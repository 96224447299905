@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@media (min-width: 1250px) and (max-width:1390px){
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1220px !important;
  }
}
body {
    margin: 0;
    font-family: 'Roboto', sans-serif;

}

img { max-width: 100%; }
.profileimg{width: 64px}
p,a, h1,h2,h3,h4,h5,h6 { font-family: 'Roboto', sans-serif; color:#000000; }

.header_btn ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0;
}

.header_btn ul li {
    padding: 0 5px;
}
.header {
    background: #F8F9FA;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.15);
}

.bnrtext_form h1 {
    font-weight: 800;
    font-size: 45px;
    line-height: 53px;
    color: #000;
}

.bnrtext_form h1 span {
    color: #ED107A;
}

.top_form_row {
    width: 95%;
    margin: 0 auto !important;
    padding: 35px 0;
}

.bnrtext_form {
    background: #FFFFFF;
    box-shadow: 0px 4px 22px 15px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 30px;
}

.bnrtext_form .form-element {
    margin-bottom: 12px;
}

.bnrtext_form .form-element label {
    margin-bottom: 5px;
    font-weight: 600;
}

.row.flex-reverse {
    flex-direction: row-reverse;
}

.form-check.form-switch { display: flex;justify-content: flex-end; margin-top: 5px; }

.form-check.form-switch input {margin-right: 10px; height: 22px; width: 42px;}

.form-check.form-switch label {
  margin-bottom: 0;
  margin-top: 3px;
  font-size: 16px;
  line-height: 21px;
  text-align: justify;
  letter-spacing: 0.05em;
  color: #636363;
}
.sitebtn {
    border: 0;
    padding: 10px 8px;
    border-radius: 5px;
    color: #fff;
  
}
.callme  {
    background: #ed107a;
}
.bnrtext_form form p {
    font-size: 14px;
}

.bnrtext_form .row.flex-reverse {
    margin-top: 15px;
}
.councelor_sec { 
    height: auto;
    background-image: url('images/bnrtoppr.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #3e1566;
    padding: 75px 0;
    margin-bottom: 25px;
 }

 .txt_img p, .txt_img h2 {
    color: #fff;
}

.councelor_sec .row {
    align-items: center;
}

.pckges {
    position: relative;
}

.flt_box {
    position: absolute;
    background: #fff;
    padding: 10px 20px;
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    text-align: center;
    width: 190px;
}

.flt_box p {
    margin: 0;
}

.flt_box.bx1 {
    left: 0;
}

.flt_box.bx2 {
    right: 70px;
}
.goal_txt {
  text-align: center;
}
.srvcont a.cta_btn {
    background: #fff;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #7030B3;
    width: 100%;
}
.flt_box.bx3 {
    left: 30%;
    transform: translateX(-50%);
    top: 85px;
}

.flt_box h5 {
    font-weight: 600;
    font-size: 25px;
    line-height: 29px;
    color: #DAB10B;
}

.flt_box p {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
/* identical to box height */
    color: #6F6F6F;
}

.councelor_sec .txt_img {
    padding-left: 50px;
}

.councelor_sec .txt_img h2 span {    
  color: #DAB10B;
}

h2.sitehdng {
    font-weight: 600;
    font-size: 35px;
    line-height: 145.19%;
    letter-spacing: -0.045em;
}

.councelor_sec .txt_img a.sitebtn {
    background: #FFFFFF;
    border-radius: 5px;
    color: #7030B3;
    text-decoration: none;
    margin-top: 10px;
    display: inline-block;
}

.srvimg {
    flex: 0 0 30%;
}

.servlist {
    padding: 25px 15px;
    background: #7030B3;
    border-radius: 5px;
    margin-bottom: 15px;
}

.srvcont h4, .srvcont p {
    color: #fff;
}

.srvcont {
    padding: 0px 25px;
}

.srvcont h4 {
    font-size: 22px;
    line-height: 26px;
    color: #FFFFFF;
}


.srvcont p {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    text-align: justify;
    letter-spacing: 0.01em;
}

.row.serv_wrpr {
    width: 90%;
    margin: 0 auto;
}

a.cta_btn {
    display: block;
    background: #fff;
    text-align: center;
    border-radius: 3px;
    padding: 6px 0;
}

.centr_hdng_txt {
    text-align: center;
    margin-bottom: 20px;
}

section.home_servc {
    background: #EAFCFC;
    padding: 40px 0;
}
a { text-decoration: none !important; }
.home_servc h2.sitehdng span {
    color: #ED107A;
}

.centr_hdng_txt p {
    width: 50%;
}

.centr_hdng_txt p {
    width: 80%;
    margin: 0 auto;
}
 h2.sitehdng span {
    color: #ed107a;
}

.goals_sec .centr_hdng_txt p {
    width: 80%;
    text-align: center;
}
.left-steps .slick-track {
    display: flex;
    flex-direction: column;
}

.left-steps .slick-track .slick-slide {
  width: 100% !important;
  max-width: 600px;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 20px 20px 0;
  background: #fff;
  margin: 20px 0;
}
.steps_txt h6 svg {
    position: absolute;
    left: -2px;
    color: #10C168;
    font-size: 18px;
    top: 1px;
}

.steps_txt {
    padding-left: 20px;
    position: relative;
}

span.rightarw {
    position: absolute;
    right: -36px;
    top: 45%;
    transform: translateY(-50%);
    background: #F093C0;
    border-radius: 50%;
    color: #fff;
    width: 38px;
    height: 38px;
    text-align: center;
    line-height: 36px;
    transition: all 0.5s;
}

.slick-current span.rightarw {
    width: 59px;
    height: 59px;
    line-height: 55px;
    background: #ED107A;
    right: -50px;
}

.slick-current span.rightarw svg {
    font-size: 35px;
}

.zone_wrp {
    position: relative;
}

.zone_filter {
    position: absolute;
    right: 15px;
    text-align: right;
    bottom: 0;
}

select {
    background: #EBF1F1;
    border: 1px solid #EFEFEF;
    border-radius: 7px;
    height: 43px;
    padding: 0 10px;
}

.crs_box {
    background: #FFFFFF;
    box-shadow: 0px 4px 16px 1px rgba(92, 92, 92, 0.1);
    border-radius: 4px;
}

.crs_cont {
    padding: 10px 15px;
}

.crs_cont  h3 {
    font-weight: 700;
    font-size: 20px;
    min-height: 40px;
    line-height: 23px;
    color: #333333;
}
span.duration {
    display: block;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    position: relative;
    left: 10px;
    padding: 5px 0px 0 0;
}

span.mode {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
/* identical to box height */
    text-align: justify;
    color: #333333;
    display: block;
    padding: 15px 0;
}

.frm_univ span {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
/* identical to box height */
    text-align: justify;
    color: #333333;
}

.frm_univ {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
}

.crs_ovrview {
    padding: 10px 10px;
    background: #fff;
    position: relative;
    margin-top: -40px;
    border-radius: 5px;
    height: 215px;
}
.blogbx  .crs_ovrview { height: 260px;}
a.cta_btn.crs_btn {
    background: #6429B2;
    border-radius: 5px;
    color: #fff;
    font-size: 15px;
    width: 100%;
    padding: 8px;
}


.filt_container  h2.accordion-header button {
    background: #6429B2;
    border-radius: 5px;
    color: #fff;
}

.filt_container .accordion-body ul {
    padding: 0;
    list-style: none;
    margin-bottom: 0;
}

.filt_container .accordion-body ul li {
    background: #FFFFFF;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 10px 10px;
    margin-bottom: 5px;
    cursor: pointer;
}

.filt_container .accordion-body {
    padding: 0;

}

.filt_container  h2.accordion-header button:after{
    background-image:url('https://sindonbschool.com/web_assest/images/acd.png');
    background-size: 75%;
    right: 12px;
    position: absolute;
    top: 10px;
}
button:focus {
    outline: none;
    box-shadow: none !important;
}

.filt_container h2.accordion-header .accordion-button.collapsed:after {
    right: 8px;
    position: absolute;
    background-size: 75%;
    top: 20px;
    height: 20px;
}

section.benft_wrpr {
    padding: 30px 0;
}

.p-boxx.pbx1 h2 {
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  color: #000;
  margin-bottom: 25px;
}
.frm_univ img {
  max-width: 165px;
}
.p-boxx.pbx1 {
    margin: 0 auto;
}

.p-boxx.pbx1 h2 span {
    color: #ed107a;
}
.crsimg img {
  height: 215px;
  object-fit: cover;
  width: 100%;
}
.icon svg {
    border: 3px solid #3fbbc0;
    border-radius: 10px;
    font-size: 35px !important;
    margin-bottom: 16px;
    color: #3fbbc0;
}

.sm-box1 {
    margin-bottom: 20px;
}

/* .bnft_img  img {
    max-height: 500px;
} */

.bnft_img {
    text-align: center;
}

.col-sm-6.count_imgs {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
}

.row.count_us.myy-d {
    margin: 0 auto;
    padding-bottom: 40px;
}

.cmpny_img {
    flex: 0 0  31%;
    border: 1px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.recruiter_sec { padding: 50px 0;}

section.recruiter_sec {
    background: #2A0856;
}

h3.rcrtr_hdng {
    color: #fff;
    display: inline-block;
    position: relative;
    width: 50%;
    margin: 0 auto;
    font-size: 35px;
    font-weight: 500;
    line-height: 30px;
}

h3.rcrtr_hdng:after {
    content: '';
    position: absolute;
    right: 0px;
    width: 150px;
    height: 2px;
    background: #fff;
    top: 16px;
}

h3.rcrtr_hdng:before {
    content: '';
    position: absolute;
    left: 0px;
    width: 150px;
    height: 2px;
    background: #fff;
    top: 16px;
}

h3.rcrtr_hdng svg {  color: #DAB10B; }

.rec_txt {
    color: #fff;
    padding: 20px 20px;
    margin: 0 auto;
    position: relative;
    width: 65%;
}
.left-steps .slick-list {
  max-height: 500px;
  overflow-y: auto;
}

.left-steps .slick-list::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.left-steps .slick-list::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

.left-steps .slick-list::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #f093c0;
}



.rec_txt h6 {
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: justify;
  letter-spacing: 0.010em;
}
.student_slide .cmp_img img {
  height: 50px;
  object-fit: contain;
}
.rec_txt p {
    color: #fff;
}

.rec_slider.d-flex {
    align-items: center;
}

.rec_slider {
    width: 90%;
    margin: 0 auto;
    margin-top: 25px;
}

.rec_img {
    flex: 0 0 25%;
}

.abt_rec p {
    margin-bottom: 0;
    text-align: justify;
    letter-spacing: 0.035em;
}

.abt_rec {
    margin-top: 20px;
}

.rec_txt svg {
    transform: rotate(180deg);
    position: absolute;
    left: -35px;
    opacity: 0.5;
    top: -18px;
    font-size: 100px;
}


.slick-dots li.slick-active button:before {
  opacity: 0 !important;
  color: #ED107A !important;
}

.slick-dots li.slick-active button {
  background: #ED107A !important;
  padding: 0 !important;
  height: 8px;
  border-radius: 5px;
  width: 25px;
}

ul.slick-dots {
  bottom: -40px;
}

.slick-dots li {
  height: auto;
  width: auto;
  margin: 0;
}

.slick-dots li button {
  width: 12px;
  padding: 0 !important;
  margin: 0 3px;
}

li.slick-active {
  margin: 0 5px;
}

.padd_50 { padding: 50px 0;}

.student_slider .slick-slide img {
    margin: 0 auto;
}

.student_slider .slick-slide > div {
    margin: 0 10px;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 21px -2px rgb(0 0 0 / 10%);
    border-radius: 4px;
  }
  .slick-list {
    margin: 0 -10px;
  }

  .student_slide {
      padding: 25px 0;
  }
  
  .stud_img img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      margin-bottom: 15px !important;
  }

  span.postn {
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #43ABA8;
}

.cmp_img img {
    max-height: 80px;
    width: 105px;
}

.cmp_img {
    margin: 15px 0 10px 0px;
}
.student_slide svg {
    color: #ED107A;
}
.student_slide p {
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    color: #000000;
}
.student_slider .slick-dots li button:before {
  color: #7030B3;
  border-radius: 3px;
  opacity: 1;
  font-size: 10px;
  top: 1px;
}
.rec_slider .slick-dots li button:before {
  color: #fff;
  border-radius: 3px;
  opacity: 1;
  font-size: 10px;
  top: 1px;
}
.goals_sec { position: relative;}
.goals_sec:before { 
    content: '';
    background-image: url('images/shape3.png');
    height: 150px;
    width: 150px;
    position: absolute;
    background-size: 100%;
    left: -6%;
    top: 25%;
    -webkit-animation: moveIn-to 20s infinite linear;animation: moveIn-to 20s infinite linear;
 }
 .planbx {
  text-align: center;
 }
.container.abs-bg {
    background-image: url('images/bg_pattern.png');
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
    background-position: bottom;
    color: #fff;
    border-radius: 20px;
    background-size: cover;
}

.abs-bg h4, .abs-bg h3,  .abs-bg p {
    color: #fff;
}

a.button-bg.btn-primary {
    color: #ed107a !important;
    font-weight: bolder;
    padding: 10px 40px;
    margin: auto;
    background: #fff !important;
    border-radius: 5px;
}
.count_txt p {
  font-weight: 400;
  font-size: 30px;
  line-height: 34px;
/* identical to box height */
  color: #000000;
  border-bottom: 1px solid #000000;
  padding-bottom: 15px;
}

.count_txt p:last-child {
  border: 0;
}
.zones {
    display: flex;
    flex-wrap: wrap;
}

.foot_box ul { list-style:none; padding: 0; }

.foot_box ul li a {
    font-size: 15px;
    cursor: pointer;
}

.zones .foot_box h5 {
    font-size: 16px;
}

.zones .foot_box {
    margin-right: 20px;
}

.zones .foot_box li {
    margin-bottom: 5px;
}

.footer-section > .container {
    border-top: 2px solid #DAB10B;
    padding-top: 40px;
}

.footer_bg_ylw {
    background: #FFF8DB;
}

.footer_bg_ylw ul {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 10px 0;
    margin: 0;
}

.footer_bg_ylw ul li a {
    padding: 0 15px;
    color: #333333;
}

.footer_btm {
    background: #E9FFFE;
}

.footer_btm .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social_media ul {
    margin: 0;
    display: flex;
    list-style: none;
    padding: 0;
}

.footer_btm p {
    margin: 0;
}

.social_media ul li {
    padding: 0 8px;
}

.social_media ul li a {
    color: #7030B3;
}

.pt-50 { padding-top: 50px; }

section.bg_voilet {
    background: #411B68;
}

.whysbs_top {
    padding: 30px 0;
}

.whysbs_top h2.sitehdng span {
    color: #DAB10B;
}

.whysbs_top a.sitebtn {
    background: #FFFFFF;
    border-radius: 5px;
    color: #7030B3;
    margin-top: 15px;
    display: inline-block;
}

.txt_img p {
    text-align: justify;
}

.txt_img {
    width: 95%;
}

.whysbs_step p {
    color: #000;
}

.whysbs_step {
    position: relative;
}

.row.stps_btmcolms {
    margin-top: -240px;
}
.padd_30 { padding: 30px 0;}
.pb-50 { padding-bottom: 50px; }
section.bg_skyb {
    background: #EAFCFC;
}
.whysbs_step h2.sitehdng {
    color: #000;
    font-weight: 800;
    font-size: 45px;
    line-height: 131.19%;
}
.stpsbox p {
    font-weight: 700;
    font-size: 16px;
    line-height: 157.19%;
/* or 25px */
    letter-spacing: 0.06em;
    color: #000000;
}

.stpsbox p span {
    color: #ED107A;
}
.stpsbox img {
    box-shadow: 0px 4px 22px 1px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin-bottom: 10px;
}

.stpsbig_img {
    text-align: center;
}

.trng_img {
    background: #F3E8FF;
    padding: 20px;
}

.indstr_txt h3 {
    font-weight: 600;
    font-size: 30px;
    line-height: 131.19%;
/* or 39px */
    color: #000000;
}

.crclm_txt p {
    font-weight: 500;
    font-size: 22px;
    
/* identical to box height, or 33px */
    margin-bottom: 0;
    color: #696969;
    margin-left: 20px;
}

.crclm_txt {
    margin: 30px 0;
}
.indstr_txt a.sitebtn {
    background: #ED107A;
    border-radius: 3px;
    width: 85%;
    display: inline-block;
    text-align: center;
}


/* WFH-CSS */


img { max-width:100%; }
.padd_50 { padding:30px 0; }

.adm_box {
  text-align: center;
  box-shadow: 0px 4px 15px 1px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 30px 30px;
}

.adm_box p {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
/* identical to box height */
  color: #000000;
}

h3.site_hdng {
  font-size: 35px;
  margin-bottom: 30px;
}

.adm_box img {
  min-height: 180px;
  margin-bottom: 15px;
}


.container.coursebrf_sec {background: #411B68;}

section.coursebrf_sec {
    padding: 50px 0;
    position: relative;
}

.crs_brftxt {
    text-align: left;
}

.crs_brftxt h2 {
    font-weight: 600;
    font-size: 35px;
    line-height: 41px;
/* identical to box height */
    color: #FFFFFF;
    margin-bottom: 18px;
}

.crs_brftxt p {
    font-weight: 500;
    font-size: 16px;
    text-align: justify;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

section.coursebrf_sec:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 50%;
    z-index: -1;
    left: 0;
    background: #411B68;
}

.crs_brfbox {
    background: #FFFFFF;
    box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 30px 20px;
}

.univ_name.d-flex {
    align-items: center;
    text-align: left;
}

.univ_name  h5 {
    margin-left: 15px;
}

.univ_name {
    margin-bottom: 15px;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 15px;
}

.ftrs p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
/* identical to box height */
    color: #333333;
}

.ftrs {
    text-align: left;
    min-width: 20%;
    padding-right: 20px;
    position: relative;
}

.ftrs h5 {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
/* identical to box height */
    color: #333333;
}

.ftrs:after {
    position: absolute;
    content: '';
    width: 1px;
    height: 50px;
    background: #D8D8D8;
    right: 28px;
    top: 6px;
}

.ftrs:last-child::after {
    display: none;
}

.specialztion h5 {
  font-weight: 400;
  font-size: 19px;
  line-height: 19px;
/* identical to box height */
  color: #333333;
  text-align: left;
  margin-bottom: 15px;
}


.specialztion ul {
  text-align: left;
  list-style: none;
  display: flex;
  padding: 0;
  flex-wrap: wrap;
}

.specialztion ul li {
  background: #FFFFFF;
  border: 1px solid #7030B3;
  padding: 6px 8px;
  margin-right: 1%;
  font-weight: 400;
  font-size: 13px;
  border-left-width: 5px;
  line-height: 14px;
/* identical to box height */
  color: #67379A;
}

.specialztion {
  border-top: 1px solid #D8D8D8;
  border-bottom:  1px solid #D8D8D8;
  padding: 12px 0;
  margin: 15px 0;
}

.totlfee p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
/* identical to box height */
  color: #333333;
  text-align: left;
}

.totlfee h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
/* identical to box height */
  color: #333333;
  margin: 0;
}

.totlfee {
  padding-right: 20px;
}

.crs_fee.d-flex {align-items: center;}

.semvise_fee.d-flex {
  border: 1px solid #E2E2E2;
  border-radius: 4px;
}

.sem_fee {
  padding: 5px 12px;
}

.sem_fee h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.intrn_txt {
  background: #411B68;
  display: flex;
  align-items: center;
  flex: 0 0 55%;
  padding: 40px 0;
}

.intrn_txt .crs_brftxt {
  padding: 0 5px;
  max-width: 85%;
  margin: 0 auto;
}

.intrn_txt h2 {
  font-size: 42px;
  line-height: 106.69%;
/* or 48px */
  letter-spacing: 0.025em;
  font-weight: 700;
}

.intrn_txt p {
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  color: #FFFFFF;
}

.intrnimg img {
  width: 100%;
  height: 460px;
  object-fit: cover;
}

.intrnimg {
  flex: 0 0 46%;
}


.commt_row {
  display: flex;
  justify-content: flex-end;
  margin-top: -100px;
}

.commt_row .bg_vlt {
  display: flex;
  background: #411b68;
  color: #fff;
}

.bgylw {
  background: #DAB10B;
}

.cmtmnt p {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #FFFFFF;
  margin: 0;
}

.cmtmnt {
  padding: 10px 10px;
  text-align: left;
  flex: 0 0 30%;
}

.cmtmnt h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 5px;
}

.bgylw h5 {
  font-weight: 600;
  font-size: 22px;
  text-align: left;
  line-height: 25px;
  color: #131313;
  margin: 0;
}

.commt_row .bgylw {
  flex: 0 0 15%;
  padding: 25px 25px;
}

.jobbox {
  display: flex;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 10px 10px;
}

.jobbox p {
  margin: 0;
  padding-left: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 112.5%;
  letter-spacing: 0.065em;
}


.hdngtxt  h3.site_hdng {
  margin-bottom: 15px;
}

.hdngtxt  p {
  font-weight: 500;
  font-size: 22px;
  line-height: 125.69%;
  letter-spacing: 0.025em;
  color: #000000;
}

.hdngtxt {
  margin-bottom: 30px;
}

.mentr {
  text-align: left;
}

.jobportl {
  text-align: right;
}

.jobportl h4 {
  margin-top: 45px;
  font-weight: 700;
  font-size: 26px;
  line-height: 125.69%;
  letter-spacing: 0.025em;
  color: #000000;
}

.mentr h4 {
  font-weight: 700;
  font-size: 26px;
  line-height: 125.69%;
  letter-spacing: 0.025em;
  color: #000000;
}

.hirerow {
  align-items: center;
  margin-bottom: 25px;
}

.hirerow ul {
  list-style: none;
  text-align: left;
}

.hirerow ul li {
  font-weight: 700;
  font-size: 20px;
  line-height: 125.69%;
/* identical to box height, or 28px */
  letter-spacing: 0.025em;
  color: #000000;
  margin: 20px 0px;
}

a { text-decoration: none !important; }

a.cta_btn {
  background: #7030B3;
  border-radius: 5px;
  color: #fff;
  padding: 10px 20px;
  display: inline-block;
}

.gettouch {
   margin-top: 30px !important;
}

section.fulljobsec {
  background: #FFE4F1;
}

.jrc {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 15px;
}

ul { list-style: none;}

.jrc p {
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 125.69%;
/* identical to box height, or 28px */
  letter-spacing: 0.025em;
  color: #000000;
}

section.fullplan_sec {
  position: relative;
}

section.fullplan_sec:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75%;
  background: #43ABA8;
  z-index: -1;
}

.planbx {
  background: #FFFFFF;
  box-shadow: 0px 4px 33px 1px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  padding: 30px 20px;
  width: 90%;
  margin: 0 auto;
}


ul.ftrss {
  padding-left: 20px;
  text-align: left;
}

section.fullplan_sec {
  position: relative;
}

section.fullplan_sec:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75%;
  background: #43ABA8;
  z-index: -1;
}



.planbx p {
  text-align: left;
}



section.fullplan_sec .hdngtxt {
  margin-top: 25px;
}

.planbx.silvrp {
  margin-top: 25px;
}

.planbx.platinmp {
  margin-top: 25px;
}
.planbx h5 {
  font-weight: 600;
  font-size: 32px;
  line-height: 41px;
/* identical to box height */
  color: #7030B3;
}

h6.price {
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
/* identical to box height */
  color: #7030B3;
}
.fullplan_sec h3.site_hdng {
  color: #fff;
  font-size: 50px;
  margin-top: 25px;
}

a.signupbtn {
  border: 2px solid #7030B3;
  border-radius: 13px;
  display: block;
  padding: 12px 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
/* identical to box height */
  color: #7030B3;
  margin-top: 25px !important;
  width: 85%;
  margin: 0 auto;
}

.abttxt h3.site_hdng {
  text-align: left;
}

.abttxt {
  padding-left: 25px;
  text-align: left;
  width: 90%;
}

span.qt_text {
  font-style: italic;
  font-weight: 600;
  font-size: 35px;
  line-height: 137.69%;
/* or 48px */
  text-align: justify;
  letter-spacing: 0.025em;
  color: #7030B3;
  display: block;
  margin-bottom: 20px;
}

.abttxt strong {
  font-size: 22px;
  line-height: 137.69%;
/* or 30px */
  text-align: justify;
  letter-spacing: 0.025em;
  color: #000000;
}

.abttxt ul {
  list-style: disc;
  padding-left: 18px;
  margin-top: 15px;
}
.abttxt ul li {
  font-size: 18px;
  line-height: 137.69%;
/* or 25px */
  text-align: justify;
  letter-spacing: 0.025em;
  color: #2F2F2F;
}

.abttxt ul li {
  font-size: 18px;
  line-height: 137.69%;
/* or 25px */
  text-align: justify;
  letter-spacing: 0.025em;
  color: #2F2F2F;
}

.text-left {
  text-align: left;
}

.abtfull_txt {
  padding: 25px 0;
}

.corebox {
  background: #FFFBED;
  padding: 15px;
}

.img_social.d-flex {
  align-items: center;
}

.name_links p {
  font-weight: 600;
  font-size: 22px;
  line-height: 131.19%;
/* identical to box height, or 29px */
  margin-bottom: 5px;
  color: #000000;
}

.name_links span {
  font-weight: 500;
  font-size: 16px;
  line-height: 131.19%;
/* identical to box height, or 21px */
  color: #000000;
}

.name_links {
  text-align: left;
  padding-left: 20px;
}

.ct_txt {
  font-weight: 500;
  font-size: 16px;
  line-height: 157.19%;
/* or 25px */
  text-align: justify;
  letter-spacing: 0.06em;
  color: #333333;
  margin-top: 15px;
}


/* sindn */


.cnct-dtils {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
}

.cnct-dtils-text p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  color: #acacac;
}

.cnct-dtils-text {
  padding-left: 10px;
}

.cnct-dtils-icn .fa {
  font-size: 30px;
  color: #48c3b2;
}

.cnct-dtils-icn {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #48c3b2;
  border-radius: 4px;
}

.cnct-dtils-text h4 a {
  font-size: 20px;
  margin-bottom: 0;
  color: #000;
  text-decoration: none;
}

.cnct-dtils-text h4 {
  margin-bottom: 0;
}

.cnct-dtils {
  margin-bottom: 30px;
}

.qual {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.qual h6,
.qual p {
  margin-bottom: 0;
}

.qual h6 {
  font-size: 20px;
  font-weight: 600;
}

.qual p {
  font-size: 18px;
  font-weight: 600;
  color: #5c5c5c;
}

.qual {
  padding: 10px;
  border-top: 1px solid #C9C9C9;
}

p.un-line {
  margin-bottom: 20px;
  margin-top: 20px;
  border-bottom: 2px dashed #b9b9b9;
  padding: 10px 0px;
  text-align: left;
}
.ml-dls h3 {
  text-align: left;
}

.clr-light {
  background: #F3FDFE;
  padding: 25px 0;
}

.qualnsocial {
  flex-direction: column;
}

.qualftext {
  max-width: 50%;
}

.cnct-dtils-bx {
  display: flex;
  text-align: left;
  margin-top: 15px;
}

h4.uni-h {
    text-align: left;
}

.bg-voilet { background: #411B68; }


.hirefrm_wrp {
  background: #FFFFFF;
  box-shadow: 0px 4px 17px 2px rgba(112, 48, 179, 0.15);
  border-radius: 12px;
  padding: 25px;
}

.hirefrm_wrp h3 {
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  color: #000000;
}

.form-control {
  background: #F1F1F1;
  border-radius: 5px;
  border: 0;
  height: 45px;
}

.hirefrm_wrp button {
  background: #7030B3;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  border: 0;
}

.input-group span {
  border: 0;
}

.input-group input {
  border-left: 1px solid #c3c3c3;
}

.qryform {
  background: #7030B3;
}

.qryform form {
  padding: 25px;
}

.qryform button {
  width: 100%;
  height: 45px;
  background: #DAB10B;
  border: 0;
}

.qryform small.text-muted.form-text {
  text-align: left !important;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF !important;
  display: inline-block;
  margin-top: 12px;
  line-height: normal;
}


.faq_accrdion .accordion-item {
  background: #FFFFFF;
  box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
  border-radius: 16px;
  margin-bottom: 25px;
  text-align: left;
  border: 0;
}

.faq_accrdion .accordion-item button.accordion-button {
  background: transparent;
  border: 0;
}

.accordion-button:focus{ box-shadow: none !important;}
.faq_accrdion .accordion-item button.accordion-button {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
}
.accordion-body {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6F6C90;
}

.contfrm_wrp {
  background: #43ABA8;
  border-radius: 11px;
  box-shadow: 0px 4px 17px 2px rgba(112, 48, 179, 0.15);
  padding: 25px;
}

.contfrm_wrp button {
  background: #FFD21B;
  border-radius: 5px;
  width: 100%;
  height: 45px;
}

.cont_icn {
  background: #ED107A;
  border-radius: 10px;
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

.cont_bx.d-flex {
  align-items: center;
}

.cont_icn {
  background: #ED107A;
  border-radius: 10px;
  height: 60px;
  width: 60px;
  margin-right: 15px;
}

.cont_bx.d-flex {
  align-items: center;
  margin-bottom: 25px;
}

.conttxt span {
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
/* identical to box height */
  text-align: justify;
  color: #333333;
  display: block;
}

.conttxt p, .conttxt a {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  text-align: left;
  line-height: 29px;
  color: #333333;
}

.std_txt p {
    font-weight: 600;
    font-size: 20px;
    line-height: 131.19%;
/* identical to box height, or 29px */
    color: #000000;
}

.std_txt ul li {
    font-weight: 500;
    font-size: 18px;
    
/* identical to box height, or 24px */
    color: #000000;
    margin: 10px 0px;
}

.std_txt ul {
    list-style: disc;
}

.stb_left.d-flex {
    align-items: center;
    margin: 20px 0;
}

.std_txt {
    padding-left: 20px;
}

.bnft_img {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.stdn_bnf_icns {
    flex: 0 0 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DCDCDC;
    border-radius: 5px;
    max-height: 145px;
    flex-wrap: wrap;
    flex-direction: column;
}
.stdn_bnf_icns p {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 131.19%;
    letter-spacing: 0.075em;
    color: #000000;
    padding-top: 15px;
}


section.coach_slider {
    background: #F0FFFF;
    padding: 50px 0;
}

.coach_slider .slick-slide > div {
    margin: 0 10px;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 21px -2px rgb(0 0 0 / 10%);
    border-radius: 4px;
}

.coach_slider .slick-slide > div img {
    width: 100%;
}

.coach_slider .slick-slide > div p {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
/* identical to box height */
    color: #000000;
    margin-top: 15px;
    margin-bottom: 5px;
}

.coach_slider .slick-slide > div span {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
/* identical to box height */
    color: #000000;
    margin-bottom: 15px;
    display: inline-block;
}

.list_topbg  { 
    background-image: url('images/college_bg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-color: #3e1566;
    padding: 75px 0;
    margin-bottom: 25px;
 }

 .bloglistbg {    
   background-image: url('images/blog_bg.png');
 }

 .clg_listftr.d-flex {
    background: #FFFFFF;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-top: -90px;
    padding: 0 20px;
}

.cl_ftr {
    padding: 20px 5px;
    align-items: center;
}

.cl_ftr p {
    margin: 0;
    font-weight: 500;
    padding-left: 12px;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #000000;
}

.padd_30 { padding:30px 0; }

.topcomp_sec h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: normal;
/* or 44px */
    letter-spacing: 0.025em;
    color: #000000;
}

.cont_icn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.cont_icn svg {
  font-size: 35px;
}

.whysbs_top p {
  font-size: 18px;
}

.whysbs_top h2.sitehdng {
  border-bottom: 1px solid #DAB10B;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.hiretxt li svg {
  color: #DAB10B;
}

.advisory_topbg  { 
  background-image: url('images/advisory_bg.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #3e1566;
  padding: 75px 0;
  margin-bottom: 25px;
}
section.loginsec {
  background: #f0f0f0;
}

.loginrow {
  background: #fff;
  width: 95%;
  margin: 0 auto;
}

.loginsec .col-sm-6.bnft_img {
  padding: 0;
  justify-content: flex-end;
}

.logintxt h3 {
  font-weight: 600;
  font-size: 40px;
  line-height: 145.19%;
  letter-spacing: -0.045em;
}
.logintxt {
  max-width: 380px !important;
  margin: 0 auto;
}

.loginform input {
  box-shadow: rgb(0 0 0 / 8%) 0px 5px 15px;
  border: none;
  border-radius: 5px;
  padding-left: 20px;
  background: #e8efee;
}

.logintxt  p {
  font-size: 18px;
  font-weight: 600;
  margin: 26px 0;
}

.loginform button {
  background: #6f30ae;
  color: #fff;
  margin-top: 15px;
  border-radius: 10px;
  border: 0px;
  width: 100%;
  height: 55px;
}

.profileform {
  width: 80%;
  margin: 0 auto;
  padding: 40px 60px;
  background: #FFFFFF;
  box-shadow: 0px 4px 22px 15px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.profileform label {
  font-weight: 500;
  font-size: 16px;
  line-height: 125.69%;
/* or 20px */
  letter-spacing: 0.025em;
  color: #333333;
}

.profileform input, .profileform select,.profileform textarea {
  border: none;
  background: #F0F0F0;
  border-radius: 5px;
}
.profileform h3 {
  font-weight: 500;
  font-size: 22px;
  line-height: 125.69%;
/* identical to box height, or 28px */
  letter-spacing: 0.025em;
  color: #000000;
  margin-bottom: 15px;
}

.profileform button {
  background: #7B00B4;
  border-radius: 7px;
  border: 0;
  height: 55px;
  width: 98%;
  margin: 0 auto;
  margin-top: 25px;
}

img.shp1 {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 99;
  height: 250px;
}

.bnr_shpwrpr {
  position: relative;
}

img.shp2 {
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: 99;
  height: 90px;
  object-fit: contain;
}

.scaling { -webkit-animation: scale 3s infinite linear; animation: scale 15s infinite linear; }
.movebounce { -webkit-animation: movebounce-up 6s infinite linear; animation: movebounce-up 6s infinite linear; }
.moving {-webkit-animation: moveIn-to 20s infinite linear;animation: moveIn-to 20s infinite linear;}
.movingup {-webkit-animation: moveUp-to 20s infinite linear;animation: moveUp-to 20s infinite linear;}

.rotated { -webkit-animation: rotated360 25s infinite linear; animation: rotated360 25s infinite linear; }
.rotated30 { -webkit-animation: rotated30 5s infinite linear; animation: rotated30 5s infinite; }

@-webkit-keyframes movebounce-up {
  0% { -webkit-transform: translateY(0px); transform: translateY(0px) }
  50% { -webkit-transform: translateY(-30px); transform: translateY(-30px) }
  100% { -webkit-transform: translateY(0px); transform: translateY(0px) }
}
@keyframes movebounce-up {
  0% { -webkit-transform: translateY(0px); transform: translateY(0px) }
  50% { -webkit-transform: translateY(-30px); transform: translateY(-30px) }
  100% { -webkit-transform: translateY(0px); transform: translateY(0px) }
}
@-webkit-keyframes moveIn-to {
  0% { -webkit-transform: translate(0, 0); transform: translate(0, 0) }
  50% { -webkit-transform: translate(50px, 0); transform: translate(50px, 0) }
  100% { -webkit-transform: translate(0, 0); transform: translate(0, 0) }
}
@keyframes moveIn-to {
  0% { -webkit-transform: translate(0, 0); transform: translate(0, 0) }
  50% { -webkit-transform: translate(50px, 0); transform: translate(50px, 0) }
  100% { -webkit-transform: translate(0, 0); transform: translate(0, 0) }
}

@keyframes moveUp-to {
  0% { -webkit-transform: translate(0, 0); transform: translate(0, 0) }
  50% { -webkit-transform: translate(0, 50px); transform: translate(0px, 50px) }
  100% { -webkit-transform: translate(0, 0); transform: translate(0, 0) }
}

@-webkit-keyframes rotated360 {
  0% { -webkit-transform: rotateZ(0deg); transform: rotateZ(0deg) }
  100% { -webkit-transform: rotateZ(-360deg); transform: rotateZ(-360deg) }
}
@keyframes rotated360 {
  0% { -webkit-transform: rotateZ(0deg); transform: rotateZ(0deg) }
  100% { -webkit-transform: rotateZ(-360deg); transform: rotateZ(-360deg) }
}
@-webkit-keyframes rotated30 {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg) }
  100% { -webkit-transform: rotate(-10deg); transform: rotate(-10deg) }
  100% { -webkit-transform: rotate(0deg); transform: rotate(0deg) }
}
@keyframes rotated30 {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg) }
  50% { -webkit-transform: rotate(-10deg); transform: rotate(-10deg) }
  100% { -webkit-transform: rotate(0deg); transform: rotate(0deg) }
}
@-webkit-keyframes scale {
  0% { -webkit-transform: scale(1); transform: scale(1) }
  100% { -webkit-transform: scale(1.3); transform: scale(1.3) }
  100% { -webkit-transform: scale(1); transform: scale(1) }
}
@keyframes scale {
  0% { -webkit-transform: scale(1); transform: scale(1) }
  50% { -webkit-transform: scale(1.3); transform: scale(1.3) }
  100% { -webkit-transform: scale(1); transform: scale(1) }
}
.mainmenu a.nav-link {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    
/* identical to box height */
    color: #000000;
}
.crs_box {
  margin-bottom: 20px;
}
.mobprofl_menu, .moblmenu_btn { display: none;}
.mob_show { display: none; }


.form-check.form-switch input:focus {
  box-shadow: none;
}


.onlymob { display: none;}


.ftrbtns {
  position: fixed;
  bottom: 5%;
  width: 100%;
  display: flex;
  z-index: 99;
  justify-content: space-around;
}

a.ftrbutton {
  background: #FFFFFF;
  border: 3px solid #ED107A;
  border-radius: 5px;
  padding: 7px 8px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
/* identical to box height */
  letter-spacing: 0.065em;
  color: #ED107A;
  min-width: 145px;
  text-align: center;
}



.crs_tabsform .nav-link {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #333333;
}

.crs_tabsform .nav-link.active {
  border: 0;
  border-bottom: 7px solid #7030B3 !important;
}
.nav-link:hover {
  border-color: transparent !important;
}

.univ_name > img {
  box-shadow: 0px 0px 12px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 15px;
}

.univ_name {
  align-items: center;
}

.uni_short {
  padding: 8px 12px;
}

.soc_icn p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 155.4%;
/* identical to box height, or 25px */
  text-align: justify;
  color: #333333;
}

.soc_icn svg {
  margin: 0 5px;
}
.unvshrt_d span {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.unvshrt_d {
  padding-right: 10px;
  border-right: 2px solid #555555;
  margin-right: 10px;
}

.unvshrt_d img {
  padding-right: 4px;
}

.d-flex.soc_icn {
  margin-top: 5px;
}
.crs_tabsform .tab-content {
    padding: 25px 0;
}
.unvshrt_d:last-child {
  border-right: 0;
}

.abt_topsec {
  max-height: 270px;
  margin-bottom: 60px;
}

.abt_topsec .whysbs_top {
  position: relative;
}

.abt_topsec  .vdosec {
  position: relative;
  top: 40px;
  padding-bottom: 60% ;
}
.abt_topsec  iframe {
  top: 0 !important;
}

.coach_img img {
  max-width: 280px;
}
/* .video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
} */
.video-responsive {
  position: relative;
}
.video-responsive iframe {
  left: 0;
  top: 140px;
  height: 280px;
  width: 100%;
  position: absolute;
}

.blogbx h3 {
  font-size: 16px;
}

.blogbx p {
  font-size: 14px;
  text-align: justify;
}
.by_date {
  margin-bottom: 8px;
}

.by_date span {
  font-size: 14px;
}
.bdtl_top h1.sitehdng {
  color: #fff;
  font-size: 30px;
}

.bdtl_top {
  padding: 30px 0;
}

.blog_dtlbox img {
  width: 100%;
}

.blog_dtlbox h2 {
  font-size: 32px;
  line-height: normal;
  font-weight: bold;
}

.trndingbox h5 {
  text-align: center;
  background: #411b68;
  color: #fff;
  padding: 15px;
  border: 1px solid #411b68;
}


.trndingbox {
  border: 1px solid #411b68;
  position: sticky;
  top: 20px;
}

.trnd_row img {
  height: 100%;
  object-fit: cover;
}

.trnd_row {
  padding: 0px 10px 10px;
}

.trnd_txt {
  padding-left: 10px;
}

.trnd_txt h6 {
  font-size: 15px;
}

.trnd_txt  p {
  font-size: 14px;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.trnd_row .by_date span {
  font-size: 12px;
  padding-right: 8px;
}

.trnd_row .by_date svg {
  font-size: 16px;
}
.trnd_txt a {
  font-size: 15px;
  font-weight: 500;
  color: #000;
}
.pt_50 { padding-top: 50px;}
.ptb_50 { padding:50px 0; }

.profilefrm_wrp {
  background: #FFFFFF;
  box-shadow: 0px 4px 22px 15px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 30px;
  margin: 50px 0;
}

.profilefrm_wrp select {
  width: 100%;
  background: #F0F0F0;
}
.profilefrm_wrp input {
  background: #F0F0F0;
  height: 45px !important;
  padding: 8px 10px !important;
  width: 100%;
  line-height: normal !important;
  border: 0;
  border-radius: 8px;
}

.form-floating>label {
  padding: 8px 6px;
}
.profilefrm_wrp  textarea {
  width: 100%;
  height: 130px;
  background: #F0F0F0;
  border: 0;
  border-radius: 8px;
}

.profilefrm_wrp label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}
.profilefrm_wrp button {
  width: 220px;
  height: 50px;
  background: #7B00B4;
  border: 0;
  margin-top: 15px;
}
.imgupload {
  position: relative;
}

.imgupload input {
  opacity: 0;
  display: block;
  position: absolute;
  left: 0;
  z-index: 9999;
  bottom: 16px;
  cursor: pointer;
}
.imgupload button {
    background: #7B00B4;
    color: #fff;
    position: relative;
    left: 15px;
    bottom: 8px;
    width: 170px;
    height: 50px;
}
.faq_left {
  /* width: 48%; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.otp_section {
  background: #f0f0f0;
}

.row.otp_row {
  background: #fff;
  width: 95%;
}

.otp_row .col-md-6.bnft_img {
  justify-content: flex-end;
  padding: 0;
}

.otp_row .login-box-mrgn {
  padding: 50px 0;
}

.otp_row .login-box {
  max-width: 400px;
  margin: 0 auto;
}
.login-box input {
  width: 100%;
  height: 60px;
}

input.box-new {
  box-shadow: rgb(0 0 0 / 8%) 0px 5px 15px;
  border: none;
  border-radius: 5px;
  padding-left: 20px;
  background: #e8efee;
}

input.getotpbtn {
  background: #6f30ae;
  color: #fff;
  margin-top: 28px;
  border-radius: 10px;
  border: 0px;
}

.login-box form {
  padding: 30px 0;
}

.ottp input {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  margin: 0px 6px;
  border: navajowhite;
  box-shadow: 0px 0px 10px 0px #0000001f;
  border-radius: 10px;
}

.otpbtn_wrp button {
  background: #6f30ae;
  margin-top: 28px;
  width: 80%;
  color: #fff !important;
  height: 60px;
}

.otpbtn_wrp {
  margin-top: 5px;
}

.otpbtn_wrp button {
  background: #6f30ae;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 0px;
  width: 75%;
  height: 55px;
}

.intrn_top_sec .cmtmnt h6 {
  color: #fff;
}

th.maxwidth {
  width: 500px !important;
}

th.width_250 {
  width: 250px;
  text-align: center;
}

th.width_500 {
  width: 500px;
  vertical-align: bottom;
}

.plan_table td span {
  display: block;
  font-weight: 500;
  font-size: 14px;
  margin-top:5px;
  line-height: 18px;
  text-align: center;
  color: #636363;
}
.plan_table tr:last-child td {
  border: 0;
}
.bg_lblue {
  background: #DDFEFD;
}

.bg_pink {
  background: #FFB8DA;
}

.bg_yellow {
  background: #FFEDA5;
}

.ftr_type {
  margin: 0 20px;
  padding: 15px;
  border-radius: 8px;
}

.ftr_type h3 {  
  font-weight: 500;
  font-size: 25px;
  line-height: 29px; 
}
.bg_lblue h3,.bg_lblue h4 {
  color: #43ABA8;
}


.ftr_type h4 {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
}

h6.cut {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #3F3F3F;
  text-decoration: line-through;
}

a.btn_ftr {
  background: #333333;
  border-radius: 5px;
  color: #fff;
  font-weight: 400;
  padding: 7px 10px;
  display: block;
  width: 90%;
  margin: 0 auto;
}

.bg_pink h3, .bg_pink  h4 { color: #7030B3; }

td svg {
  color: #26BFAD;
  font-size: 40px !important;
}

.featrs h4 {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
/* identical to box height */
  color: #636363;
}

.featrs p {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #636363;
}

.plan_table tr td {
  padding: 15px 0;
  border-bottom: 1px solid #B1B1B1;
}

.plan_table tr th {
  padding: 15px 0;
  border-bottom: 1px solid #000000;
}

td.red svg {
  color: #DC2727;
}

.ftr_hdng p {
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  color: #636363;
}

.ftr_hdng p {
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  color: #636363;
}

.ftr_hdng h4 {
  background: #7030B3;
  border-radius: 4px;
  display: inline-block;
  padding: 12px 35px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
  position: relative;
  left: -35px;
  bottom: 5px;
}


.plan_table {
  border: 1px solid #C6C6C6;
  box-shadow: 0px 4px 12px 1px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.ftr_hdng h4:after {
  content: '';
  position: absolute;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-bottom: 17px solid #3C0A71;
  margin: 2rem;
  left: -31px;
  top: 9px;
  transform: rotate(45deg);
}
section.plan_sec  h3.site_hdng {
  color: #ED107A;
  font-weight: 600;
  font-size: 35px;
}
p.off {
  background: #DD4819;
  border-radius: 3px;
  display: inline-block;
  margin-bottom: 8px;
  padding: 8px 16px;
  position: relative;
  left: -70px;
  font-weight: 500;
  font-size: 14px;
  top: -5px;
  line-height: 16px;
  color: #FFFFFF;
}

p.off:after {
  content: '';
  position: absolute;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #71100A;
  margin: 2rem;
  left: -31px;
  top: -2px;
  transform: rotate(45deg);
}
.only_mobile {
  display: none !important;
}
svg.red {
  color: #DC2727 !important;
}
.chkoutbox h4 {
  font-size: 22px;
  line-height: 26px;
/* identical to box height */
  letter-spacing: 0.025em;
  color: #000000;
  padding-left: 20px;
}

.chkoutbox {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 20px 0px;
  min-height: 415px;
}

.chk_table table {
  width: 100%;
}

.chk_table table th {
  background: #411B68;
  font-size: 18px;
  line-height: 21px;
/* identical to box height */
  letter-spacing: 0.025em;
  color: #FFFFFF;
  font-weight: 500;
  padding: 10px;
}

.chk_table table th:first-child {
  padding-left: 22px;
}

.chk_table .plntext {
  padding: 10px 22px;
}

.chk_table td h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
/* identical to box height */
  letter-spacing: 0.025em;
  color: #636363;
}

.chk_table td h6 {
  font-size: 16px;
  line-height: 18px;
/* identical to box height */
  letter-spacing: 0.025em;
  color: #636363;
}

.plntext ul li {
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  letter-spacing: 0.025em;
  color: #636363;
  margin-bottom: 5px;
}
.chk_table td {
  vertical-align: baseline;
}

.plntext ul li svg {
  font-size: 18px !important;
  margin-right: 3px;
  position: relative;
  top: -1px;
}

.ord_summary {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 25px 20px;
}

.ord_summary h4 {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.025em;
  color: #000000;
  margin-bottom: 15px;
}

.ord_summary p {
  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.025em;
  color: #747474;
  margin-bottom: 10px;
}

button.cpncode {
  background: transparent;
  border: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
  padding-left: 0;
  letter-spacing: 0.025em;
  text-decoration: underline !important;
  display: inline-block;
  color: #58BAAB;
  margin-bottom: 15px;
}
button.cpncode:hover {
  background: transparent;
  color: inherit;
}
.cpn_btn input {
  background: #F0F0F0;
  border-radius: 5px;
  border: 0;
}

.cpn_btn button {
  background: #DAB10B;
  border-radius: 5px;
  border: 0;
  width: 100%;
  height: 43px;
}

.p0 {
  padding: 0;
}

.total_prc_row p {
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.025em;
  color: #000000;
}

.total_prc_row {
  border-top: 1px solid #EDEDED;
  margin-top: 20px;
  padding-top: 20px;
}

.total_prc_row p span {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.025em;
  color: #747474;
}

.totl_btn button {
  background: #3E1566 !important;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  border: 0;
  letter-spacing: 0.025em;
  margin-top: 20px;
  width: 100%;
  height: 48px;
}
.cpn_wrpper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cpnbox {
  flex: 0 0 48%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #EFF7FF;
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px;
}

.cpn_modal .modal-lg {
  max-width: 600px;
}

.cpn_amt {
  background: #FF1165;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cpn_amt p {
  margin: 0;
  font-size: 15px;
  color: #fff;
  font-weight: 500;
}

p.offamnt {
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
/* identical to box height */
  letter-spacing: 0.025em;
  color: #3E3E3E;
  margin-bottom: 5px;
}

.cpnbx span {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
/* identical to box height */
  letter-spacing: 0.025em;
  color: #747474;
}

.cpnbx {
  align-items: center;
  margin-bottom: 5px;
}

.cpnbx  p {
  margin: 0;
  background: #FFD9D9;
  border-radius: 3px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
/* identical to box height */
  letter-spacing: 0.025em;
  color: #FF1111;
  padding: 7px 7px;
  margin-left: 6px;
}

a.apply_cpn {
  background: #7030B3;
  border-radius: 3px;
  display: block;
  text-align: center;
  font-size: 14px;
  
/* identical to box height */
  padding: 5px 0;
  letter-spacing: 0.025em;
  color: #FFFFFF;
  margin-top: 10px;
}
.bgblue {
  background: #00C2FF;
}

.bgyellow {
  background: #FFCC00;
}

.bgyellow p {
  color: #000;
}

.succs_box {
  background: #FFFFFF;
  border: 1px solid #D2D2D2;
  border-radius: 13px;
  padding: 20px;
  text-align: center;
}

.succs_box svg {
  color: #58BAAB;
  font-size: 80px;
  margin-bottom: 10px;
}

.succs_box h4 {
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
/* identical to box height */
  letter-spacing: 0.025em;
  color: #636363;
}

.pym_dtl {
  border-top: 1px dashed #ABABAB;
  padding-top: 20px;
  margin-top: 25px;
}

.pym_dtl h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
/* identical to box height */
  letter-spacing: 0.025em;
  color: #000000;
  text-align: left;
  margin-bottom: 15px;
}

.pym_dtl p {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
/* identical to box height */
  letter-spacing: 0.025em;
  color: #636363;
}

.success {
  color: #00B1A6;
}

.success_dtl {
  background: #FFFFFF;
  border: 1px solid #B7B7B7;
  border-radius: 13px;
  padding: 20px;
}

.success_dtl h4 {
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
/* identical to box height */
  letter-spacing: 0.025em;
  color: #000000;
  margin-bottom: 16px;
}

.success_dtl h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
/* identical to box height */
  letter-spacing: 0.025em;
  color: #000000;
  margin-bottom: 4px;
}

span.exp {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
/* identical to box height */
  letter-spacing: 0.025em;
  color: #747474;
  display: block;
  margin-bottom: 15px;
}

.success_dtl.plntext svg {
  color: #58BAAB;
}

.failure svg {
  color: #FF0000;
}

.fail {
  color: #FF0000 !important;
}
span.tryagain {
  font-weight: 500;
  font-size: 16px;
  position: relative;
  line-height: 19px;
/* identical to box height */
  letter-spacing: 0.025em;
  color: #009AFF;
  top: -1px;
  left: 4px;
}

span.tryagain svg {
  font-size: 18px;
  position: relative;
  top: 4px;
  color: #009AFF;
}

a.tryagain {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
/* identical to box height */
  letter-spacing: 0.025em;
  position: relative;
  top: -2px;
  color: #009AFF;
  left: 5px;
}

a.tryagain svg {
  color: #009AFF;
  font-size: 17px;
  position: relative;
  top: 5px;
}











@media(max-width:1360px) {
  .frm_univ img {
      max-width:100px;
  }
  .crs_cont h3 { font-size:16px; }
  .mainmenu a.nav-link {
    font-size: 15px;
  }
  .cWrlwk li {
    padding: 18px 6px !important;
  }
  a.dropdown-item {
    font-size: 14px;
  }
  .dropdown-menu {
      position: relative;
      margin-left: -35px;
      margin-top: 6px;
  }
}
@media(max-width:640px) {

  .ftr_nameprice {
      display: flex;
      align-items: center;
      justify-content: space-between;
  }

  .ftr_type {
      margin: 0;
      padding: 15px 0;
  }

  p.off {
      left: -15px;
  }

  .ftr_type h4 {
      font-size: 25px;
  }


  .row.only_mobile {
       margin: 0;
   }
   .only_desktop {
    display: none !important;
   }
   .only_mobile {
    display: block !important;
   }
   .crs_tabsform .row {
        margin: 0;
   }
   .crs_tabsform {
        padding-top: 0;
   }

    .crs_tabsform .nav-link {
        font-size: 15px;
    }
    .univ_name h5 {
        margin-left: 0;
    }
    
    .uni_short {
        padding: 8px 0;
    }

    .crs_tabsform .nav-link.active {
        border-bottom-width: 4px !important;
    }
   .mob_show { display: block; }
   .mobprofl_menu,.moblmenu_btn { display: block; }
   .mob_hide { display: none; }
   .mobprofl_menu {
        text-align: center;
        width: 80%;
        margin: 0 auto;
    }
    .mobprofl_menu h6 {
        margin: 16px 0;
        font-weight: 500;
        font-size: 25px;
        line-height: 29px;
        letter-spacing: 0.01em;
        color: #000000;
    }
    .mainmenu li.nav-item {
      padding: 5px 10px;
    }
    a.navbtn {
      color: #fff;
    }
    .mobprofl_menu p {
        font-weight: 500;
        font-size: 12px;
        line-height: 144.19%;
        letter-spacing: 0.1em;
        color: #919191;
    }

    .moblmenu_btn {
      background: #7030B3;
      border-radius: 6px;
      color: #fff;
      text-align: center;
      padding: 10px 5px;
      position: absolute;
      bottom: 30px;
      width: 90%;
      left: 0;
      margin: 0 auto;
      right: 0;
    }
    .top_form_row {
        width: 100%;
    }
    
    .bnrtext_form h1 {
        font-size: 28px;
        line-height: 35px;
    }
    
    .bnrtext_form {
        padding: 0;
        box-shadow: none;
    }
    img.logoimg {
        width: 140px;
    }
    .mainmenu nav {
        padding: 0;
    }
    .form-check.form-switch {
        justify-content: flex-start;
    }
    
    button.callme.sitebtn {
        width: 100%;
        padding: 8px 5px;
    }
    
    .bnr_shpwrpr {
        display: none;
    }
    .councelor_sec .txt_img {
      padding-left: 0px;
    }
    .councelor_sec {
      height: auto;
      padding: 25px 0 125px 0;
    }
  
    .councelor_sec h2.sitehdng {
        font-size: 32px;
    }
    .councelor_sec {
      background-image: none;
    }
    .flt_box {
      position: relative;
    }

    .flt_box.bx1 {
      left: 15px;
    }

    .flt_box.bx2 {
      right: -120px;
      top: 15px;
    }
    .councelor_sec .txt_img {
      width: 90%;
      margin: 0 auto;
    }
    .flt_box.bx3.movingup {
      left: 20px;
      top: 30px;
      transform: none;
      animation: none;
    }
    .pckges {
      margin-top: 25px;
  }

  .councelor_sec .txt_img a.sitebtn {
    position: absolute;
    text-align: center;
    display: inline-block;
    left: 0;
    right: 0;
    bottom: 20px;
    width: 82%;
    margin: 0 auto;
}
.centr_hdng_txt p {
  width: 90%;
  text-align: justify;
  margin: 0 auto;
}
section.container.councelor_sec {
    position: relative;
}
.flt_box {
  padding: 6px 15px;
}
h2.sitehdng {
  font-size: 22px;
}
.flt_box h5 {
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 2px;
}

.flt_box p {
  font-size: 15px;
}
h2.sitehdng {
  font-size: 22px;
}

.servlist.d-flex {
  flex-direction: column;
}

.srvimg {
  width: 100%;
  max-height: 150px;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 15px;
}

.srvimg img {
  width: 100%;
}

.srvcont {
  padding: 0 0;
}

.srvcont h4 {
  font-size: 20px;
}

.srvcont p {
  line-height: normal;
}

.row.serv_wrpr {
  width: 100%;
}
.goals_sec  .centr_hdng_txt p {
    display: none;
}

.left-steps {
    display: none;
}
.zone_filter {
  position: relative;
  text-align: center;
  right: 0;
  margin-top: 10px;
}

.zone_filter select {
  width: 94%;
  margin: 0 auto;
}

.filwrap {
  margin: 0 0  20px 0;
}
.p-boxx.pbx1 h2 {
  font-size: 30px;
  line-height: 35px;
}
.count_txt p {
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 10px;
}
h3.site_hdng {
  font-size: 24px;
  margin-bottom: 10px;
}
.faq_accrdion .accordion-item button.accordion-button {
  font-size: 16px;
}
.cmpny_img {
    height: 90px;
    padding: 5px;
}
h3.rcrtr_hdng {
  width: 90%;
  font-size: 20px;
}

h3.rcrtr_hdng:before {
  width: 50px;
  left: -5px;
}

h3.rcrtr_hdng:after {
  width: 50px;
  right: -5px;
}
.rec_slide.d-flex {
  flex-direction: column;
}

.rec_txt h6 {
  font-size: 20px;
  line-height: normal;
}

.rec_txt {
  padding: 20px 0;
  width: 95%;
}
.rec_txt svg {
  display: none;
}

.student_slider .slick-slide > div {
  margin: 0 5px;
}

.student_slide p {
  font-size: 18px;
  margin-bottom: 5px;
}

span.postn {
  font-size: 18px;
}

.foot_box {
  width: 50%;
  float: left;
}
.zones .foot_box {
  flex: 0 0 46%;
  margin-right: 10px;
}

.foot_box.foot_fulbox {
  width: 100%;
}

.foot_box.foot_fulbox ul {
  display: flex;
  flex-wrap: wrap;
}

.foot_box.foot_fulbox ul li {
  flex: 0 0 50%;
  margin-bottom: 5px;
}


.footer_bg_ylw ul li {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
/* identical to box height */
  letter-spacing: 0.03em;
  color: #333333;
  text-align: center;
}

.footer_bg_ylw ul li a {
  padding: 0 8px;
}
p.credit {
  position: absolute;
  bottom: 12px;
  font-size: 14px;
  left: 0;
  margin: 0 auto;
  text-align: center;
  right: 0;
}

.footer_btm {
  padding-bottom: 50px;
  position: relative;
  padding-top: 6px;
}

.social_media ul li {
  padding: 0px 3px;
}

.footer_btm img.logoimg {
  width: 160px;
}
.mainmenu {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 999;
  background: #fff;
}

section.homebanner {
  margin-top: 60px;
}

.onlymob { display: block;}
.goals_sec .row { margin: 0;}
.crs_ftrs {
  flex-wrap: wrap;
  justify-content: space-between;
}

.ftrs:after{ display:none; }

.ftrs {
  flex: 0 0 50%;
}

.specialztion ul li {
  margin: 5px 10px 2px 0;
}

.crs_fee.d-flex {
  flex-wrap: wrap;
}

.semvise_fee.d-flex {
  flex-wrap: wrap;
}

.totlfee {
  margin-bottom: 12px;
}

/* .faq_right {
  width: 100%;
  margin: 0;
} */
.faq_left .accordion-item {
  flex: 0 0 98%;
  margin: 1%;
}
section.whysbs_top {
  margin-top: 85px;
}

.order2m {
  order: 2;
}

.order1m {
  order: 1;
}

.whysbs_top .txt_img {
  margin-top: 20px;
}
.whysbs_step h2.sitehdng {
  font-size: 28px;
}

.stpsbig_img {
  display: none;
}

.row.stps_btmcolms {
  margin-top: 0;
}

.stpsbox img {
  width: 100%;
}

.indstr_txt h3 {
  font-size: 20px;
}

.indstry_sec  h2.sitehdng {
  font-size: 30px;
  text-align: left;
}

.padd_50 {
  padding: 25px 0;
}
.intrn_top_sec .cmtmnt h6 {
  color: #fff;
}

.crclm_txt {
  align-items: center;
}
.crclm_txt p {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
  color: #696969;
  margin-left: 16px;
}
.crclm_txt {
  margin: 15px 0;
}


.ftr_lists {
  display: flex;
}

.ftr_lists svg {
  color: #26BFAD;
  font-size: 30px !important;
  z-index: 99;
  position: relative;
}

.ftr_dtext h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #636363;
}

.ftr_dtext p {
  font-size: 12px;
  line-height: 16px;
  text-align: justify;
  color: #636363;
}

.ftr_dtext {
  padding-left: 12px;
}
.ftr_pln_dtl a.btn_ftr {
  display: flex;
  text-align: center;
  width: 130px;
  justify-content: center;
}

.ftr_pln_dtl {
  padding-bottom: 25px;
}
.ftr_pln_dtl a.btn_ftr {
  display: flex;
  text-align: center;
  width: 130px;
  justify-content: center;
}

.ftr_pln_dtl {
  padding-bottom: 25px;
}

.planbox {
  margin-bottom: 30px;
}

.planbox:last-child {
  margin-bottom: 0;
}
.ftr_lists {
  position: relative;
}

.ftr_lists:before {
  content: '';
  position: absolute;
  background: #fff;
  width: 23px;
  height: 23px;
  display: block;
  left: 3px;
  top: 3px;
  border-radius: 50%;
}


}


.bottom-fixed {
  display: none;
}
















/* DASHboard CSS */

.dashmenu .logo_prof_wrp {
  flex: 0 0 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.dashmenu  nav {
  flex-wrap: wrap;
}

.prof_notf {
  align-items: center;
}

.prof_notf .head_prf {
  padding-left: 15px;
  align-items: center;
}

.prof_notf .head_prf .hdrprf_txt {
  padding-right: 15px;
}

.hdrprf_txt h5 {
  margin: 0;
}

.hdrprf_txt p {
  margin: 0;
}

.bgblack {
  background: #191D33;
  color: #fff;
}

.hdrprf_txt p, .hdrprf_txt h5 {
  color: #fff;
}

.job_type ul {
  display: flex;
  justify-content: space-around;
}

.job_type ul li {
  padding: 2px 15px;
  border: 1px solid #DDDDDD;
  border-radius: 13.5px;
  cursor: pointer;
  transition: all 0.5s;
}
.job_type ul li.active {
  color: #00C2FF;
  border-color: #00C2FF;
}

.job_type ul li:hover {
  color: #00C2FF;
  border-color: #00C2FF;
}
.job_list {
  display: flex;
  justify-content:space-between;
  margin-bottom: 15px;
}

.job_lr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.job-left ul {
  display: flex;
  margin-bottom: 6px;

}
.job-left ul li {
  padding-right: 10px;
}


.oppurtnty_box {
  background: #FFFFFF;
  box-shadow: 0px 0px 13px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 15px;
}

.job_type {
  padding: 0;
}

ul {
  padding: 0 !important;
}

.job_list img {
  width: 115px;
  height: 115px;
}

.job_text {
  flex: 0 0 72%;
}

.job-left h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}


.job-left ul li {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #747474;
}

p.job_prc {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #58BAAB;
  margin-bottom: 7px;
}

.jb_close {
  border-top: 1px solid #EDEDED;
  padding-top: 6px;
}

.jb_close p {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #747474;
}

.job-left ul li svg {
  font-size: 12px;
  position: relative;
  top: -2px;
}
span.c_dot {
  width: 5px;
  height: 5px;
  display: inline-block;
  background: #b3aeae;
  border-radius: 50%;
  position: relative;
  top: -2px;
  left: -2px;
}

a.applybtn {
  background: #A4E3DA;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #1E6C60;
  padding: 5px 15px;
  cursor: pointer;
}
button.applybtn {
  background: #A4E3DA;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  margin-top: 15px;
  line-height: 28px;
  color: #1E6C60;
  border: 0;
  padding: 5px 15px;
}
.vwall_btn {
  background: #DFF3FE;
  border-radius: 2px;
  text-align: center;
  font-weight: 500;
  padding: 10px 0px;
  font-size: 15px;
  line-height: 18px;
  display: block;
  letter-spacing: 0.025em;
  color: #00C2FF;
}


.mycrses h5 {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  margin-left: 4px;
  line-height: 21px;
/* identical to box height */
  color: #747474;
}

.crs_txt img {margin-right: 2px;}

.mycrses  h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
/* identical to box height */
  color: #3E3E3E;
  margin-top: 5px;
}

.dates {
  display: flex;
  margin-right: 30px;
}
.years  h4 {
  margin-right: 44px;
}

.cardbox {
  background: #FFFFFF;
  box-shadow: 0px 0px 13px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 15px;
}

h3.dash_hdng {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
/* identical to box height */
  letter-spacing: 0.025em;
  color: #000000;
}

.referbx img {
  max-width: 112px;
}

.refer_txt h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
/* identical to box height */
  color: #000000;
  margin-bottom: 0;
}

.refer_txt span {
  font-weight: 400;
  font-size: 11px;
  
/* identical to box height */
  display: inline-block;
  color: #747474;
}

.referbx {
  justify-content: space-between;
}

.refer-sec .cardbox {
  padding: 15px 5px;
}

.refer_txt {
  flex: 0 0 60%;
}

p.refercode {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.095em;
  color: #58BAAB;
  margin: 10px 0 0 0;
  background: #DFF3FE;
  padding: 2px 5px;
}

a.copy {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #747474;
  display: inline-block;
}

.refer_txt h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 0px;
}

.refer-sec {
  margin-top: 25px;
}

.sharelist .twitter {
  color: #2DAAE1;
}

.sharelist .linkedin {
  color: #2489BE;
}

.sharelist .facebook {
  color: #1877F2;
}

.sharelist .mail {
  color: #9BBC82;
}

.sharelist .whatsapp {
  background: linear-gradient(0deg, #20B038 0%, #60D66A 100%);
  color: #fff;
  border-radius: 50%;
}

.sharelist svg {
  margin: 0 3px;
  font-size: 20px;
}
.cch_wrp h4 {
  font-weight: 500;
  font-size: 19px;
  line-height: 27px;
  color: #3E3E3E;
  margin: 0;
}

.coach_txt p {
  font-weight: 500;
  font-size: 14px;
  color: #747474;
  margin: 0;
}

.coach_txt span {
  font-weight: 500;
  font-size: 12px;
  color: #747474;
  display: block;
}

.cch_btns.d-flex {
  margin-top: 5px
}

.cch_btns a {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #FFFFFF;
  padding: 5px 6px;
  display: inline-block;
  border-radius: 2px;
  margin-right: 2px;
}

.cch_btns a svg {
  font-size: 10px;
}
.pink {
  background: #FF1165;
}
.blue {
  background: #009AFF;
}
.green {
  background: #26BFAD;
}
.cch_wrp { justify-content: space-between;}
.cch_wrp img { width: 100px; }
.mycoach h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.mycoach .cardbox {
  margin-bottom: 15px;
}

.mycoach .cardbox {
  margin-bottom: 15px;
}

.vdos img {
  padding: 0 5px;
}

.vdos img {
  width: 170px;
}

.d-flex.vdos {
  justify-content: space-around;
}

.vdo_vwall {
  margin-top: 15px;
}

.notf-hdng-row {
  display: flex;
  justify-content: space-between;
}

.notf_hdng h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
/* identical to box height */
  margin: 0;
  color: #000000;
}

p.n_date {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
/* identical to box height */
  color: #A6A8BB;
}

.notf_hdng span {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
/* identical to box height */
  color: #747474;
  display: block;
}

p.ntf_txt {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #A6A8BB;
  margin-top: 5px;
  margin-bottom: 5px;
}

.notf_list {
  padding: 5px 0;
}

.notf_type ul {
  justify-content: flex-start;
}

.notf_type ul li {
  margin-right: 12px;
}


.custm_calender .react-calendar__navigation button {
  min-width: 30px;
}

.custm_calender .react-calendar__navigation {
  margin: 0;
}

.custm_calender button.react-calendar__tile {
  font-size: 12px;
  padding: 6px;
}

.custm_calender .react-calendar__month-view__weekdays__weekday {
  padding: 2px;
  font-size: 10px;
}

.react-calendar.custm_calender {
  background: #FFFFFF;
  box-shadow: 0px 0px 13px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border: 0;
}

.custm_calender .react-calendar__viewContainer {
  padding: 5px;
}

.job_list_type ul {
  display: flex;
}

.job_list_type ul li {
  padding: 3px 15px;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  line-height: 33px;
/* identical to box height */
  letter-spacing: 0.025em;
  transition: all 0.5s;
  color: #000000;
  border-radius: 4px;
  margin: 0 7px;
}

.job_list_type li.active,.job_list_type ul li:hover {
  background: #009AFF;
  color: #fff !important;
}

.job_list_type {
  padding: 20px 5px;
}
.searchrow .srchform input {
  background: #EAEEF2!important;
  border-radius: 7px;
  border: 0;
  height: 60px;
  padding-left: 25px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.025em;
  color: #9F9F9F;
}

.form-control:focus { box-shadow: none !important;}

.searchrow form {width: 75%;}

.searchrow {
  height: 60px;
  background: #eaeef2;
  border-radius: 8px;
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.zoneslct {
  display: flex;
  align-items: center;
  background: #D4DFE9;
  border-radius: 7px;
  width: 155px;
  height: 50px;
  padding: 2px 5px;
  cursor: pointer;
}
.zone_drpdwn form {
  width: 100%;
}
.zoneslct svg {
  font-size: 17px;
}

.zoneslct p {
  margin: 0;
  padding: 5px 10px 5px 4px;
  width: 110px;
  font-size: 18px;
  line-height: 27px;
/* identical to box height */
  letter-spacing: 0.025em;
  color: #000000;
}

.zone_selectbx {
  position: relative;
  right: 10px;
}
.zone_drpdwn {
  max-width: 400px;
  background: #FFFFFF;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  padding: 15px 15px;
  width: 100%;
  position: absolute;
  right: 0;
  top: 70px;
}
.zonebtns {
  display: flex;
  padding: 0 15px;
}

.zonebtns button {
  width: 90px;
  margin-right: 10px;
}
.zone_drpdwn label {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.025em;
  color: #000000;
}

.clrbtn {
  background: transparent!important;
  border: 0!important;
  font-weight: 500!important;
  font-size: 14px!important;
  line-height: 18px!important;
/* identical to box height */
  color: #747474!important;;
}

.jbapplybtn {
  background: #A0E8FF !important;
  border-radius: 3px !important;
  border: 0 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px!important;
  color: #1877F2 !important;
}

.clrbtn:hover {
  background: #a0e8ff!important;
  color: #fff !important;

}

.applybtn:hover {
  background: #009aff!important;
  color: #fff !important;

}
.joblistbox img {
  width: 80px;
  height: 80px;
}

.joblistbox {
  padding: 10px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 7px;
}

.joblistbox .jb_close p {
  margin: 0;
}

.joblistbox > p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: justify;
  color: #747474;
}

.joblistbox .dtl_btn {
  background: #A4E3DA;
  border-radius: 3px;
  display: block;
  text-align: center;
  width: 100%;
  border: 0;
  padding: 8px 2px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #1E6C60;
}

.job_modal p,.job_modal li {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: justify;
  color: #000000;
}

.job_modal  h5 {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: justify;
  color: #000000;
}

.job_modal  ul {list-style: disc;padding-left: 20px !important;}

.job_popupright h4 {
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  text-align: justify;
  letter-spacing: 0.06em;
  color: #000000;
}

.job_popupright {
  border: 1px solid #747474;
  border-radius: 12px;
  padding: 10px 15px;
}

.job_popupright p {
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  text-align: justify;
  letter-spacing: 0.06em;
  color: #747474;
}

.job_popupright p svg {
  font-size: 13px;
  position: relative;
  top: -2px;
}
.job_popupright p.job_prc {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #58BAAB;
  text-align: left;
}


.jbimg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20px;
}

.jbimg img {
  width: 100px;
}

.job_modal  a.applybtn {
  margin: 0 auto;
  position: relative;
  top: 25px;
  width: 170px;
  text-align: center;
}

.job_modal button.btn-close {
  position: absolute;
  right: 12px;
  top: 10px;
}

.job_modal  .modal-header {
  height: 115px;
}
.appointmnt_sec {
  display: flex;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  justify-content: space-around;
}

.left_appointmnet {
  flex: 0 0 40%;
  padding-right: 20px;
  border-right: 1px solid #EDEDED;
  margin-right: 20px;
}

.coachprfl img {
  border-radius: 50%;
  width: 100px;
}

.coachprfl h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #3E3E3E;
}

.coachprfl .coach_txt {
  padding-left: 10px;
}


.left_appointmnet h4 {
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
/* identical to box height */
  color: #000000;
}

.left_appointmnet p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
/* identical to box height */
  color: #747474;
  margin-bottom: 10px;
}

.left_appointmnet p svg {
  margin-right: 5px;
}

.scdul h5 {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
/* identical to box height */
  color: #747474;
}

p.scdl_time {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #009AFF;
  display: flex;
}

p.scdl_time svg {
  color: #747474;
}

.scdul {
  margin-top: 20px;
}

.coachprfl {
  margin-bottom: 15px;
}


.right_appointmnt .custm_calender button.react-calendar__tile {
  font-size: 15px;
  padding: 15px;
  border-radius: 50%;
}
.right_appointmnt .react-calendar {
  border: 1px solid #DDDDDD;
  border-radius: 19px;
  padding: 5px;
}

.right_appointmnt .custm_calender .react-calendar__month-view__weekdays__weekday {
  padding: 5px;
  font-size: 15px;
}
.right_appointmnt .custm_calender .react-calendar__navigation button { min-width: auto;}

.apnttxt p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #747474;
}
.sdlbtnn a.applybtn {
  display: inline-block;
  text-align: center;
  width: 100%;
  padding:15px;
  margin-top: 20px;
}

.right_appointmnt {
  flex: 0 0 55%;
}

.time_slot {
  list-style-type: none;
  margin: 0;
  padding-left: 25px;
  max-width: 300px;
  width: 100%;
}
.time_slot li {
  float: left;
  margin: 0 0px 15px 0;
  width: 100%;
  height: 52px;
  position: relative;
}


.time_slot label, .time_slot input {
 display:block;
 position:absolute;
 top:0;
 left:0;
 cursor: pointer;
 right:0;
 bottom:0;
}

.time_slot input[type="radio"] {
 opacity:0.011;
 z-index:100;
}

.time_slot input[type="radio"]:checked + label {
 border: 2px solid #009AFF;

}

.time_slot label {
  padding: 5px;
  cursor: pointer;
  z-index: 90;
  border: 2px solid #000;
  border-radius: 6px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
  color: #000;
}

.time_slot label:hover {
  background:#DDD;
}

span.brdcrmb {
  position: relative;
  left: 12px;
  top: -2px;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.025em;
  color: #000000;
  display: inline-block;
}

.hdng_brdcrmb h5 {
  display: inline-block;
}

span.brdcrmb svg {
  font-size: 18px;
  color: #009aff;
  position: relative;
  top: -2px;
}
.notfction_accrd h2.accordion-header button {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  padding-bottom: 30px;
}

p.by_date {
  position: absolute;
  bottom: 0;
}

p.by_date span {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #747474;
  padding-right: 5px;
}

.notfction_accrd .accordion-button:not(.collapsed) {
  color: #009AFF;
  background-color: #fff;
}
.notfction_accrd .accordion-body {
  background: #F0F3F7;
}
.accordion-body p {
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  color: #A6A8BB;
}

a.blog_readmr {
  background: #FFEBF1;
  border-radius: 2px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.025em;
  color: #FF1165;
  padding: 10px 15px;
}

.qry_modal p {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: justify;
  letter-spacing: 0.025em;
  color: #000000;
}

.qry_modal  .prgrm {
  padding: 0 10px 25px;
}

.qry_modal .modal-dialog {
  max-width: 600px;
}

.qry_modal  .modal-header {
  border: 0;
}

.qry_modal  form.row .col {position: relative;}

.qry_modal form.row .col svg {
  position: absolute;
  left: 17px;
  top: 10px;
}

.qry_modal form.row .col input {
  background: #EFF7FF;
  border-radius: 4px;
  border: 0;
  padding: 10px 10px 10px 35px;
  max-width: 200px;
    font-weight: 500;
    font-size: 14px;
    text-align: justify;
    letter-spacing: 0.025em;
    color: #000000;
}
.prgrm button {
  background: #009AFF;
  border-radius: 4px;
  border: 0;
  width: 85%;
  margin-left: 20px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.025em;
}
.almhdng  a {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.025em;
  color: #000000;
}
.almhdng h3.sitehdng {
  font-weight: 600;
  font-size: 27px;
  line-height: 45px;
  letter-spacing: 0.025em;
  color: #000000;
}


/* fahim css */

.job_intro_container {
  padding: 45px 0;
}

.job_intro_content {
  background: #FFFFFF;
  box-shadow: 0px 0px 13px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 10px 34px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 25px;
}

.job_intro_text {
  width: calc(100% - 220px);
  padding: 12px 0;
  padding-right: 20px;
  font-size: 14px;
  line-height: 20px;
}

.job_intro_text h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 8px;
}

.job_intro_text p {
  margin-bottom: 0; text-align: justify;
}

.job_intro_img {
  width: 220px;
}

.job_intro_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.applicants_review_area {
  display: flex;
  flex-wrap: wrap;
}

.applicants_review_list {
  width: 75%;
}

.applicants_review_item {
  padding-right: 30px;
  width: 25%;
}

.applicants_review_box {
  background: #FFFFFF;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  text-align: center;
}

.applicants_review_box h6 {
  font-size: 30px;
  line-height: 30px;
  font-weight: 600;
  padding: 14px 14px 10px;
  margin-bottom: 0;
}

.applicants_review_box h6 span {
  font-size: 14px;
  font-weight: 400;
  display: block;
  line-height: 22px;
  margin-top: 2px;
}

.applicants_review_icon {
  background-color: #8301F4;
  padding: 14px 12px;
  border-radius: 0 0 6px 6px;
}

.applicants_review_item:nth-child(2) .applicants_review_icon {
  background-color: #FFCC00;
}

.applicants_review_item:nth-child(3) .applicants_review_icon {
  background-color: #26BFAD;
}

.applicants_review_item:nth-child(4) .applicants_review_icon {
  background-color: #FF1165;
}

.applicants_review_icon img {
  max-height: 44px;
  margin: 0 auto;
}

.applicants_chartBox {
  width: 25%;
  background-color: #fff;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}
.prof_notf.d-flex svg {
  color: #00C2FF;
}
.AppliedInstitute_wapper h3 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 22px;
}

.AppliedInstitute_tabs_list {
  margin-bottom: 21px;
  display: flex;
  flex-wrap: wrap;
}

.AppliedInstitute_tabs_item {
  margin-right: 24px;
}

.AppliedInstitute_tabs_link {
  border: none;
  padding: 4px 24px;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  border-radius: 3px;
  background-color: transparent;
  transition: all .3s ease-in;
}

.AppliedInstitute_tabs_link.active, .AppliedInstitute_tabs_link:hover {
  background-color: #009AFF;
  color: #fff;
}

.institutes_card_list {
  margin-bottom: -20px;
  width: calc(100% + 24px);
  margin-left: -12px;
}

.institutes_card_item {
  width: 30%;
  padding: 0 12px;
  margin-bottom: 20px;
}

.institutes_card_box {
  height: 100%;
  padding: 20px 18px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.institutes_card_icon {
  height: 65px;
  margin-bottom: 18px;
}

.institutes_card_icon img {
  width: auto;
  max-width: 90%;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
  display: block;
}

.institutes_card_content h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  margin-bottom: 10px;
}

.institutes_card_inn {
  display: flex;
  padding: 0;
  list-style: none;
  font-size: 12px;
  margin-bottom: 0;
  width: calc(100% + 14px);
  margin-left: -7px;
}

.institutes_card_inn li {
  width: 50%;
  padding: 0 7px;
  margin-top: 3px;
}

.institutes_card_inn li strong {
  font-weight: 600;
  min-width: 66px;
  display: inline-block;
}
body { background-color: #F6F9FB; font-family: 'Poppins', sans-serif;}
.prfile_plane_content { background: #FFFFFF; box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.15); border-radius: 5px; }
.prfile_plane_list { list-style: none; padding: 0; margin: 0; display: flex; flex-wrap: wrap; padding: 3px; }
.prfile_plane_item { width: 33.33%; padding: 3px; }
.prfile_plane_box { background-color: #A4E3DA; padding: 8px 7px; height: 100%; }
.prfile_plane_box h6 { font-size: 11px; font-weight: 400; margin-bottom: 6px; }
.prfile_plane_box h4 { font-size: 15px; font-weight: 500; color: #1E6C60; margin-bottom: 0; }
.prfile_right { background: #FFFFFF; box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.15); border-radius: 5px; padding: 30px 22px; }

/*******************/
.prfile_right .nav-tabs { border: none; }
.prfile_right .nav-tabs .nav-item { flex: 1; }
.prfile_right .nav-tabs .nav-link { width: 100%; border: none !important; background-color: #F0F3F7; border-radius: 0 !important; color: #000; font-weight: 500; font-size: 15px; }
.prfile_right .nav-tabs .nav-link.active { background-color: #009AFF; color: #fff; }
.prfile_content_title { padding-left: 46px; position: relative; margin-bottom: 20px; }
.prfile_content_title img { position: absolute; left: 0; width: 34px; top: 1px; }
.prfile_content_title h4 { font-size: 18px; font-weight: 600; margin-bottom: 0; }
.education_details_list { list-style: none; padding: 0; margin: 0; padding-left: 16px; }
.education_details_list > li { padding-left: 30px; position: relative; padding-bottom: 40px; }
.education_details_list > li::before { content: ''; position: absolute; left: 0; width: 1px; height: 100%; background-color: rgba(198, 198, 198, 0.4); top: 5px; }
.education_details_list li h5 { font-size: 14px; font-weight: 500; color: #747474; margin-bottom: 4px; }
.education_details_inn_list { padding: 0; margin-bottom: 0; font-size: 14px; color: #747474; list-style: none; line-height: 21px; }
.education_details_inn_list li { margin-right: 12px; }
.education_details_list > li:last-child { padding-bottom: 0; }
.education_details_list > li:last-child::before { display: none; }
.education_details_list > li::after { content: ''; position: absolute; width: 12px; height: 12px; background-color: #009AFF; border-radius: 50%; left: -5px; top: 3px;  animation: dash_scale 1.8s infinite; }
.education_details_list > li:nth-child(even)::after { background-color: #8300F4; animation: dash_scale_1 1.8s infinite; }
.personal_info_from .form-group { margin-bottom: 13px; }
.personal_info_from .form-group label { font-size: 14px; color: #000; margin-bottom: 5px; line-height: 18px; }
.personal_info_from .form-group .form-control, .personal_info_from .form-group .form-select {
  height: 40px;
  background-color: #F0F3F7;
  border-radius: 0;
  border-color: #F0F3F7;
  font-size: 14px;
  box-shadow: none !important;
}
.personal_info_from .input-group-text { width: 40px; padding: 0; background-color: #EBEBEB; border-radius: 0; border: none; display: flex; align-items: center; justify-content: center; }
.personal_info_from .input-group-text svg { width: 20px; }
.personal_info_from .personal_info_title { font-size: 16px; font-weight: 500; margin-bottom: 8px; margin-top: 16px; }
@keyframes dash_scale {
  0% {box-shadow: 0px 0px 0px 1px rgb(0 154 255 / 10%);}
  50% {box-shadow: 0px 0px 0px 5px rgb(0 154 255 / 10%);}
}
@keyframes dash_scale_1 {
  0% {box-shadow: 0px 0px 0px 1px rgba(131, 0, 224, .10);}
  50% {box-shadow: 0px 0px 0px 5px rgba(131, 0, 224, .10);}
}
.prfile_container { padding: 45px 0; }
.profile_left_box { background: #FFFFFF; box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.15); border-radius: 5px; padding: 20px; }
.profile_left_img { margin: 0 auto 8px; display: block; width: 86px; height: 86px; }
.profile_left_head { text-align: center; margin-bottom: 10px; }
.profile_left_img img { width: 100%; height: 100%; object-fit: contain; }
.profile_left_head h5 { font-size: 20px; font-weight: 600; margin-bottom: 0; }
.profile_left_list { padding: 0; list-style: none; margin-bottom: 0; }
.profile_left_list li { font-size: 15px; font-weight: 400; line-height: 22px; color: #747474; }
.profile_left_list li + li { margin-top: 8px; }
.profile_left_list li strong { font-weight: 600; color: #000; margin-right: 10px; font-size: 16px; }
.profile_left_list li  p { margin-bottom: 0; margin-top: 10px; font-size: 15px; }

.formbox {
  background: #FFFFFF;
  box-shadow: 0px 0px 13px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 20px 40px;
}

.formbox label {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.025em;
  color: #000000;
}

.formbox button {
  background: #009AFF;
  border-radius: 3px;
  width: 200px;
  margin: 0 auto;
  border: 0;
  height: 46px;
}


.up_skilling_content {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 20px 24px;
  display: flex;
  flex-wrap: wrap;
}

.up_skilling_left {
  width: 55%;
  padding-right: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.up_skilling_right {
  width: 45%;
  padding-top: 10px;
}

.up_skilling_left h3 {
  font-size: 28px;
  line-height: 37px;
  font-weight: 700;
  color: #000;
  margin-bottom: 16px;
  border-bottom: 1px solid #000000;
  padding-bottom: 14px;
}

.up_skilling_left h3 span {
  color: #FF1165;
}

.up_skilling_left p {
  margin-bottom: 18px;
}

.up_skilling_left h6 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}

.up_skilling_left h6 span {
  color: #FF1165;
}

.up_skilling_r_icons {
  padding: 0;
  list-style: none;
  margin-bottom: 16px;
}

.up_skilling_r_icons li {
  width: 50%;
  padding-right: 20px;
  margin-bottom: 20px;
}

.up_skilling_r_icon_box {
  position: relative;
  padding-left: 50px;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.up_skilling_r-icon {
  position: absolute;
  left: 0;
  width: 40px;
  top: 0;
}

.up_skilling_r-icon img {
  width: 100%;
}

.up_skilling_r_icons li h4 {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 600;
}

.up_skilling_r_btn {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 14px);
  margin-left: -7px;
}

.up_skilling_r_btn a {
  flex: 1;
  margin: 0 7px;
}

.btn_pink {
  background: #FF1165;
  border-radius: 6px;
  text-align: center;
  padding: 10px 20px;
  color: #fff;
  text-decoration: none;
  border: 1px solid #FF1165;
  font-weight: 500;
  transition: all .3s ease-in;
}

.btn_blue_outline {
  background: transparent;
  border-radius: 6px;
  text-align: center;
  padding: 10px 20px;
  font-weight: 500;
  color: #009AFF;
  text-decoration: none;
  border: 1px solid #009AFF;
  transition: all .3s ease-in;
}

.btn_pink:hover {
  background-color: transparent;
  color: #FF1165;
  border-color: #FF1165;
}

.btn_blue_outline:hover {
  background-color: #009AFF;
  color: #fff;
}

.up_skilling_bottom {
  width: 100%;
  margin-top: 20px;
}

.up_skilling_b_logos {
  padding: 0;
  list-style: none;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
}

.up_skilling_b_logo img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.request_call_btn {
  background: linear-gradient(90.37deg, #00C2FF 9.04%, #009AFF 97.47%);
  border-radius: 41px;
  color: #fff;
  padding: 10px 28px;
  display: inline-flex;
  text-decoration: none;
  transition: all .3s ease-in;
  align-items: center;
}

.request_call_btn img {
  margin-right: 5px;
}

.request_call_btn:hover {
  background: linear-gradient(270deg, #00C2FF 9.04%, #009AFF 97.47%);
  color: #fff;
}
.d_blgbox {
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 20px;
}

.d_blgbox img {
  width: 100%;
}

.d_blgbox .d_blgstxt {
  padding: 10px;
}

.d_blgstxt h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.025em;
  color: #000000;
  margin-bottom: 5px;
}

.bydate_blg p {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #747474;
  margin: 0;
}

.bydate_blg p svg {
  font-size: 15px;
  position: relative;
  top: -2px;
}

.bydate_blg {
  margin-bottom: 15px;
}

a.readmr_blg {
  background: #DFF3FE;
  border-radius: 2px;
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.025em;
  color: #00C2FF;
}

.left-steps .slick-list {
  max-height: 500px;
  overflow-y: auto;
}

.left-steps .slick-list::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.left-steps .slick-list::-webkit-scrollbar{
	width: 12px;
	background-color: #F5F5F5;
}

.left-steps .slick-list::-webkit-scrollbar-thumb{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #f093c0;
}


.star-rating button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 40px;
}
.star-rating .on {
  color: #FFCC00;
}
.star-rating .off {
  color: #ccc;
}
.star-rating {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}


.feedbck_colm p {
  margin: 0;
}


.catg_like{
  margin-top: 20px !important;
  margin-bottom:10px;
  background-color: transparent;
  border-radius: 4px;
  overflow: hidden;
  flex: 0 0 48%;
  text-align: center;
}

.catg_like label {
  line-height: 2em;
  width: 100%;
  border-radius: 25px;
}

.catg_like label span {
  text-align: center;
  padding: 3px 0;
  display: block;
  font-weight: 500;
  border: 1px solid #000;
  border-radius: 25px;
  font-size: 14px;
}

.catg_like label input {
  position: absolute;
  display: none;
  color: #fff !important;
}
/* selects all of the text within the input element and changes the color of the text */
.catg_like label input + span{color: #000;}

.catg_like input:checked + span{background-color: #FFCC00;   border-color: #FFCC00;}

/* This will declare how a selected input will look giving generic properties */
.catg_like input:checked + span {
    color: #000;
}

.tellmore {
  padding: 25px 0;
}

form.feedbckrow {
  width: 85%;
  margin: 0 auto;
}

.tellmore textarea {
  border: 1px solid #000000;
  border-radius: 9px;
}

.tellmore button {
  background: #A4E3DA;
  border-radius: 5px;
  border: 0;
  width: 100%;
  display: block;
  font-weight: 600;
  font-size: 17px;
  margin-top: 20px;
  line-height: 30px;
/* identical to box height */
  color: #1E6C60;
}

a.nocall {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  margin-top: 15px;
  display: inline-block;
}

.feedbck_colm {
  padding-top: 25px;
}

.dashmenu .nav-item a {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.025em;
  color: #FFFFFF;
}

.packg_active {
  display: flex;   
  align-items: center;
}

.packg_active p {
    color: #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.025em;
}

.packg_active p {
    margin: 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: 0.025em;
    color: #FFFFFF;
    padding: 0 8px;
}


p.pkgname {
    border: 1px solid #00C2FF;
    border-radius: 3px;
    padding: 4px 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.025em;
    color: #00C2FF;
}

.dashmenu .nav-item a svg {
  font-size: 15px;
  position: relative;
  top: -2px;
}

.hdrprf_txt h5 {
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.025em;
  color: #FFFFFF;
}


a.d-flex.prfl_link {
  align-items: center;
}

.hdrprf_txt p {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.025em;
  color: #FFFFFF;
}


.no_course .crs_text {text-align: center;}

.no_course .crs_txt {
    text-align: center;
    padding: 15px 0px;
}

.no_course .crs_txt img {
    margin-bottom: 15px;
}


@media (max-width:991.98px) {
  .up_skilling_left h3 {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 10px;
      padding-bottom: 10px;
  }
  .up_skilling_left {
      font-size: 16px;
      line-height: 22px;
  }
  .up_skilling_left p {
      margin-bottom: 16px;
  }
  .up_skilling_left h6 {
      font-size: 16px;
  }
  .up_skilling_r_icon_box {
      padding-left: 40px;
  }
  .up_skilling_r-icon {
      width: 35px;
  }
  .up_skilling_r_icons li {
      padding-right: 12px;
  }
}


@media (max-width:767.98px) {
  .up_skilling_content {
      padding: 20px 15px;
  }

  .up_skilling_left {
      width: 100%;
      padding: 0;
  }

  .up_skilling_left h3 {
      font-size: 22px;
      line-height: 26px;
  }

  .up_skilling_right {
      width: 100%;
      order: 1;
  }

  .up_skilling_left p {
      margin-bottom: 10px;
  }

  .up_skilling_r_icons li h4 {
      font-size: 11px;
      line-height: 12px;
  }

  .up_skilling_r_icon_box {
      padding-left: 32px;
  }

  .up_skilling_r-icon {
      width: 28px;
      top: 4px;
  }

  .up_skilling_r_icons {
      margin-bottom: 0;
  }

  .up_skilling_r_icons li {
      margin-bottom: 14px;
  }

  .btn_pink, .btn_blue_outline {
      padding: 8px 16px;
      font-size: 14px;
  }

  .up_skilling_b_logos {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 5px 10px;
  }

  .request_call_btn {
      width: 40px;
      height: 40px;
      padding: 6px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
  }

  .request_call_btn img {
      margin: 0;
      width: 18px;
  }
}





@media (max-width:767.98px){
    .prfile_container{padding: 40px 0;}
    .profile_left_list li { font-size: 14px; }
    .profile_left_list li strong { font-size: 15px; }
}

@media (max-width:767.98px){
  .prfile_right { padding: 0 20px 24px; }
  .prfile_right .nav-tabs { width: calc(100% + 40px); margin-left: -20px; border-radius: 6px 6px 0 0; overflow: hidden; }
  .prfile_right .nav-tabs .nav-link { padding: 11px 16px; }
}


/* ======================== 1199px ============================== */
@media (max-width: 1199.98px) {
  .institutes_card_item {
    width: 33.33%;
  }
}

/* ======================== 991px ============================== */
@media (max-width: 991.98px) {
  .institutes_card_item {
    width: 50%;
  }
}

/* ======================== 768px ============================== */
@media (max-width: 767.98px) {
  .institutes_card_item {
    width: 100%;
  }

  .institutes_card_icon {
    height: 55px;
  }

  .institutes_card_content h5 {
    font-size: 16px;
    line-height: 22px;
  }

  .institutes_card_inn {
    font-size: 12px;
  }

  .institutes_card_inn li strong {
    min-width: 64px;
  }

  .job_intro_text {
    width: 100%;
    padding: 0;
  }

  .job_intro_img {
    margin: 0 auto;
    margin-top: 12px;
  }

  .job_intro_content {
    padding: 24px;
  }

  .applicants_review_list {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -10px;
  }

  .applicants_review_item {
    width: 50%;
    padding: 0 10px;
    margin-bottom: 10px;
  }

  .applicants_review_box h6 {
    font-size: 24px;
    line-height: 26px;
  }

  .applicants_review_box h6 span {
    font-size: 12px;
    line-height: 13px;
  }

  .applicants_review_icon img {
    max-width: 35px;
  }

  .AppliedInstitute_wapper h3, .job_intro_text h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .AppliedInstitute_tabs_item {
    margin-right: 12px;
  }

  .AppliedInstitute_tabs_link {
    font-size: 14px;
    padding: 4px 20px;
    margin-bottom: 10px;
  }

  .AppliedInstitute_tabs_list {
    justify-content: center;
  }
  .packg_active {
    display: none;
  }
  nav.sc-jTrPJq.bIBmsg {
    padding: 0;
}

.dashmenu .logo_prof_wrp {
    justify-content: flex-end;
}

.ZhPfC div {
    background: #fff !important;
    height: 0.25rem !important;
    width: 1.8rem !important;
}
.ZhPfC { left:15px; right: unset; }
.head_prf   img {
  width: 40px;
}

.hdrprf_txt {
  padding-right: 5px !important;
}

.head_prf.d-flex {
  padding-left: 3px;
}

.logo_prof_wrp .logo {
  position: relative;
  left: -40px;
}
.job_type ul {
  overflow-y: auto;
  align-items: center;
  justify-content: flex-start;
}

.ptb_50 {
  padding: 20px 0;
}

.job_type ul li {
  text-align: center;
  padding: 5px 15px;
  flex: 0 0 25%;
  margin: 0 3px;
  font-size: 12px;
  margin-bottom: 12px;
}
.job_list img {
  width: 80px;
  height: 80px;
}

.job-left h4 {
  font-size: 16px;
  line-height: 16px;
}

.job-left ul li {
  font-size: 11px;
}

p.job_prc {
  font-size: 12px;
}

.job_lr {
  align-items: flex-start;
}

.jb_close p {
  font-size: 10px;
  margin-bottom: 5px;
}

.jb_close {
  border: 0;
}

.job_list {
  border-bottom: 1px solid #EDEDED;
}

.job_list a.applybtn {
  font-size: 11px;
  padding: 5px 7px;
}
.cch_btns.d-flex {
  position: absolute;
  left: 2px;
  bottom: 0;
}

.coach_txt {
  padding-left: 10px;
}

.cch_btns a {
  padding: 5px 10px;
  font-size: 12px;
}

.cch_btns a svg {
  font-size: 14px;
}
.cch_wrp {
  position: relative;
  justify-content: flex-start;
  padding-bottom: 30px;
}
.cch_wrp img {
  width: 80px;
}
.cch_wrp h4 {
  font-size: 16px;
}

.bottom-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: block;
  z-index: 9999;
}

.bottom-fixed ul {
  display: flex;
  justify-content: space-around;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.15);
  margin: 0;
  padding: 12px  0 !important;
}

.bottom-fixed ul li a {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #000000;
}
.job_list_type ul {
  overflow-y: auto;
  align-items: center;
  justify-content: flex-start;
}

.job_list_type ul li {
  text-align: center;
  padding: 5px 15px;
  flex: 0 0 25%;
  margin: 0 3px;
  font-size: 12px;
  margin-bottom: 12px;
}
.searchrow {
  width: 100%;
  height: 50px;
}

.zoneslct {
  width: 105px;
  height: 40px;
}

.searchrow .srchform input {
  height: 45px !important;
}

.job_modal  .modal-content {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: visible;
}
.jbimg {
  position: relative;
  transform: none;
  left: 0;
  top: 0;
}

.job_modal a.applybtn {
  top: 0;
}
.applicants_chartBox.cardbox {
  width: 100%;
}
.appointmnt_sec.cardbox {
  flex-wrap: wrap;
  padding: 8px;
}

.left_appointmnet {
  flex:  0 0 100%;
  margin-right: 0;
  padding: 0 5px;
}

.d-flex.calndr_time {
  flex-wrap: wrap;
}

.time_slot {
  padding-left: 6px;
  margin-top: 20px;
}
form.feedbckrow {
  width: 100%;
}
.catg_like label span { font-size: 12px; }
a.nocall {
  font-size: 14px;
}



}

@media(max-width:1300px){
  .dashmenu .nav-item a {
    font-weight: 400;
    font-size: 14px;
  }
}

.messagex{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 2px;
}


button.tblbtn {
  border-radius: 3px;
  background: #FC0;
  border: 0;
  padding: 8px 14px;
}
button.vw_btn {
  border-radius: 3px;
  background: #58BAAB;
  border: 0;
  padding: 8px 14px;
}


.dashmenu li.nav-item.d-flex svg {
  font-size: 15px;
  position: relative;
  top: 2px;
  left: -4px;
}
.calndr_time {
  align-items: flex-start;
}

ul.time_slot {
  max-height:350px;
  overflow: auto;
}

.time_slot input[disabled] + label {
  border-color: #dddddd;
  color: #dddddd;
  background: #eee;
}

.choose-av {
  display: flex;
  align-items: center;
}

.choose-av .rdbnt {
  margin-right: 15px;
}

.rdbnt label {
  margin-left: 5px;
  position: relative;
  top: -1px;
}

.dashmenu .dropdown-menu {
  background: #191d33;
}
.dashmenu a.dropdown-item:hover {
  background: transparent;
}

/* .slick-slide.slick-active.slick-current {
  max-width: 400px !important;
} */

.zone_filter select {
  cursor: pointer;
} 


/* newcss */

.home_servc .srvimg img {
    height: 100%;
    object-fit: cover;
}

.goal_txt img {
  margin: 0 auto;
}
.padd_50 {
  padding: 30px 0;
}

.trnd_img {
  flex: 0 0 35%;
}

.nav-tabs .nav-link.active {
  border-bottom: 5px solid #7030B3!important;
  border-width: 0 0 5px 0;
}

.nav-link.active:hover {
  border-color: #7030B3!important;
}
.nav-tabs .nav-link {
  color: #333;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media(max-width:1366px){
  .left-steps .slick-track .slick-slide {
    max-width: 540px;
  }
  .padd_50 {
    padding: 30px 0;
}
}



.univ-texts ul {
  list-style: disc;
  padding-left: 40px !important;
}

.univ-texts ul li { margin-bottom:10px;}

.unibrfsec::before {
  height: 85% !important;
}

.unibrfsec.coursebrf_sec {
  padding-bottom: 0;
}

.unibrfsec .unvshrt_d span {
  color: #fff;
}

.unibrfsec  svg {
  color: #fff;
}

.unibrfsec p {
  color: #fff;
}

.unibrfsec .unvshrt_d {
  border-color: #fff;
}

.notfication_section {
  padding: 15px;
  background: #2a0856;
  border-radius: 12px;
}

.notf_row, .notf_row h5 {
  color: #fff;
}

.notf_row, .notf_row p {
  color: #fff;
}

.notf_row h5 {
  font-size: 16px;
}

.notf_row svg {position:absolute;left: 2px;}

.notf_row {
  padding-left: 40px;
  margin-bottom:16px;
}

span.dates {
  color: #dab10b;
  font-weight: 500;
}

.dflexbtwn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notf_hdr h3 {
  color: #fff;
  font-size: 24px;
  margin: 0;
}

.notf_hdr {
  margin-bottom: 20px;
  border-bottom: 1px solid #fff;
  padding-bottom: 10px;
}

.notf_hdr a {
  color: #dab10b;
  font-weight: 500;
}


.notification_modal .modal-content {
  border-radius: 12px;
  position: relative;
}

.notf_hdr  button.btn-close {
  position: absolute;
  right: 0px;
  z-index: 999;
  background-color: #fff;
  top: -5px;
  opacity: 1;
  border-radius: 50%;
  font-size: 12px;
}
.chart_content_outer {
    height: 80vh;
    max-height: 750px;
}

.chatlist {
    width: 320px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    margin-right: 20px;
    height: 100%;
}

.chat_outer_body {
    width: calc(100% - 340px);
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    overflow: hidden;
    height: 100%;
}


.chatlist_header {
    border-bottom: 10px solid #fff;
}

.chatlist_h_nav {
    padding: 0;
    margin: 0;
    list-style: none;
    border-bottom: 1px solid #ededed;
}

.chatlist_h_nav_link {
    padding: 16px 24px 8px;
    display: block;
    font-size: 20px;
    font-weight: 500;
    color: #000 !important;
    text-decoration: none;
    border-bottom: 5px solid transparent;
}

.chatlist_h_nav_link.active {
    border-color: #009AFF;
}


.chatlist_body {
    height: calc(100% - 70px);
    overflow-x: hidden;
    overflow-y: auto;
}

.chatlist_b_list {
    padding: 0;
    list-style: none;
}


.custom_scroll::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #f5f5f5;
}

.custom_scroll::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
    border-radius: 0px;
}

.custom_scroll::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #c5c5c5b8;
}

.chatlist_b_box {
    position: relative;
    padding: 12px 20px;
    display: block;
    padding-left: 94px;
    text-decoration: none;
    min-height: 82px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    transition: all .3s ease-in;
}

.chatlist_b_img {
    position: absolute;
    width: 60px;
    height: 60px;
    left: 20px;
    top: 12px;
}

.chatlist_b_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chatlist_b_box h4 {
    color: #3E3E3E;
    font-size: 16px;
    margin-bottom: 2px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.chatlist_b_box h6 {
    font-size: 13px;
    color: #747474;
    font-weight: 500;
    margin-bottom: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.chatlist_b_item+.chatlist_b_item {
    border-top: 1px solid #f5f5f5;
}

.chatlist_b_box:hover, li.chatlist_b_item.active .chatlist_b_box {
    background-color: #efefef;
}

.chatlist_b_item.active .chatlist_b_img::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: #67ef00;
    border-radius: 50%;
    top: 2px;
    right: 3px;
}



.chat_head {
    display: flex;
    border-bottom: 1px solid #ededed;
    padding: 14px 30px;
    align-items: center;
}

.chat_h_profile {
    display: flex;
    position: relative;
    padding-left: 82px;
    min-height: 70px;
    flex-flow: column;
    justify-content: center;
    color: #747474;
    margin-right: 20px;
}

.chat_h_p_img {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 0;
    top: 0;
}

.chat_h_p_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chat_h_profile h4 {
    font-size: 18px;
    margin-bottom: 0;
    color: #3E3E3E;
}

.chat_h_profile h5 {
    font-size: 14px;
    font-weight: 500;
    color: #747474;
    margin-bottom: 0;
}

.chat_h_profile p {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0;
}

.chat_h_action {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.chat_h_a_btn, .btn-chat_h_a_btn {
    width: 36px;
    height: 36px;
    line-height: 36px;
    background-color: transparent;
    border-radius: 50%;
    text-align: center;
    margin-left: 14px;
    color: #000;
    transition: all .3s ease-in;
    padding: 0 !important;
    border: none !important;
}

.chat_h_a_drop .dropdown-menu {
    min-width: 130px;
    padding: 4px 0;
}

.chat_h_a_drop .dropdown-menu .dropdown-item {
    font-size: 12px;
}

.chat_h_a_drop .dropdown-menu .dropdown-item:hover, .chat_h_a_drop .dropdown-menu .dropdown-item:focus {
    background-color: #ededed !important;
    color: #111 !important;
}

.chat_h_a_btn:hover, .btn-chat_h_a_btn:hover, .chat_h_a_btn:hover, .btn-chat_h_a_btn:focus {
    background-color: #ededed;
    color: #000;
}

.chat_h_a_btn svg, .btn-chat_h_a_btn svg {
    width: 22px;
}

.btn-chat_h_a_btn::after {
    display: none;
}


.chat_body {
    height: calc(100% - 191px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 15px;
}

.chat_b_list {
    padding: 20px 30px;
    margin: 0;
    list-style: none;
}

.chat_b_by {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.chat_b_by_img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    font-weight: 500;
}

.chat_b_by_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.chat_b_by h5 {
    margin-bottom: 0;
    font-size: 15px;
    color: #3E3E3E;
}

.chat_b_c_item {
    background: #F3EFFD;
    border-radius: 5px;
    font-size: 12px;
    padding: 10px 14px;
    display: inline-block;
    line-height: 16px;
}

.chat_b_c_item+.chat_b_c_item {
    margin-top: 8px;
}

.chat_b_content {
    max-width: 85%;
    margin-bottom: 8px;
}

.chat_b_time {
    font-size: 12px;
    color: #747474;
    line-height: 14px;
}

.chat_b_time svg {
    width: 14px;
    vertical-align: bottom;
    height: 14px;
    margin-right: 2px;
}


.chat_b_item+.chat_b_item {
    margin-top: 22px;
}

.chat_b_item.by_user {
    text-align: right;
}

.by_user .chat_b_by {
    flex-direction: row-reverse;
}

.by_user .chat_b_by_img {
    margin-right: 0;
    margin-left: 10px;
}

.by_user .chat_b_content {
    margin-left: auto;
}

.by_user .chat_b_c_item {
    background: #E1F3FC;
}



.chat_footer {
    padding: 10px 16px 16px;
    background-color: #fff;
}

.chat_footer_box {
    border: 1px solid #ededed;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.chat_footer_input {
    width: calc(100% - 92px);
    padding-right: 10px;
}

.chat_footer_action {
    width: 92px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chat_footer_input input {
    width: 100%;
    background: #F5F9FC;
    border-radius: 8px;
    border: none !important;
    padding: 12px 20px;
    font-size: 13px;
    height: 44px;
}

.chat_footer_input input:focus {
    outline: 1px solid #dddddd;
}

.chat_footer_attach {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    line-height: 42px;
    border: 1px solid #ededed;
    justify-content: center;
    border-radius: 50%;
    transition: all .3s ease-in;
    color: #959595;
}

.chat_footer_sent {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    line-height: 42px;
    justify-content: center;
    border-radius: 50%;
    background-color: #399DED;
    color: #fff;
    border: none;
    transition: all .3s ease-in;
}

.chat_footer_sent svg {
    width: 19px;
    margin-left: 2px;
}

.chat_footer_sent:hover {
    background-color: #008eff;
}

.chat_footer_attach:hover {
    background-color: #ededed;
    color: #727272;
}

.chat_footer_attach svg {
    width: 19px;
}



@media (max-width:991.98px) {
    .chatlist {
        width: 100%;
        margin: 0; height: auto;
    }

    .chatlist_h_nav_link {
    font-size: 16px;
    padding: 12px 18px 6px;
    border-width: 3px;
}

.chatlist_b_list {
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    margin-bottom: 0;
}
.chatlist_b_list::-webkit-scrollbar {
    width: 0; height: 0;
}

.chatlist_b_item {
    max-width: 240px;
}

.chatlist_body {
    height: auto;
    overflow: hidden;
}

.chatlist_header {
    border-bottom: 0;
}

    .chat_outer_body {
        width: 100%;
        margin-top: 12px;
    }

    .chat_head {
        padding: 12px 18px;
    }

    .chat_h_profile {
        padding-left: 54px;
        min-height: 44px;
        margin-right: 10px;
    }

    .chat_h_p_img {
        width: 44px;
        height: 44px;
    }

    .chat_h_profile h4 {
        font-size: 15px;
    }

    .chat_h_profile h5 {
        font-size: 12px;
    }

    .chat_h_profile p {
        font-size: 9px;
    }

    .chat_h_a_btn, .btn-chat_h_a_btn {
        width: 28px;
        height: 28px;
        line-height: 28px;
        margin-left: 10px;
    }
    .chart_content_outer {
        height: auto;
        max-width: max-content;
    }
    .chat_body {
        height: 100%;
        max-height: 500px;
    }

    .chat_h_a_btn svg, .btn-chat_h_a_btn svg {
        font-size: 17px;
    }

    .chat_footer {
        padding: 10px 16px;
    }

    .chat_footer_box {
        padding: 5px;
    }

    .chat_footer_input {
        width: calc(100% - 76px);
        padding-right: 8px;
    }

    .chat_footer_input input {
        padding: 10px 15px;
        height: 38px;
    }

    .chat_footer_attach, .chat_footer_sent {
        width: 34px;
        height: 34px;
        line-height: 34px;
    }

    .chat_footer_attach svg, .chat_footer_attach svg {
        width: 16px;
    }

    .chat_footer_action {
        width: 74px;
    }

    .chat_b_by_img {
        width: 32px;
        height: 32px;
    }

    .chat_b_by h5 {
        font-size: 14px;
    }

    .chat_b_c_item {
        font-size: 11px;
    }

    .chat_b_list {
        padding: 16px 22px;
    }

    .chat_b_content {
        max-width: 90%;
    }


}